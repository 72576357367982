import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from './GamesetCard.module.scss';
import { Counter, ICounterProps } from '../components/Counter';
import { BuyButton, IBuyButtonProps } from '../components/BuyButton';
import { DrawInfo } from '../../../components/DrawInfo';

import { IGame } from '../../../interfaces';
import { priceFormatter } from '../utils';
import { noop } from '../../../utils/noop';
import { GAMESET_TICKETS_AMOUNT_MAP } from '../../../constants';
import { moneyFromMDE } from '../../../utils/dataUtils';

export interface IGamesetCardProps {
	className?: string;
	game: IGame;
	setsNumber?: number;
	onSetNumberChange?: (value: number, gameId: IGame['game_id']) => void;
	onBuy?: (value: number, gameId: IGame['game_id']) => void;
}

export const GamesetCard = ({
	className,
	game,
	setsNumber = 0,
	onSetNumberChange = noop,
	onBuy = noop,
}: IGamesetCardProps) => {
	const { t } = useTranslation('gameCard');
	const { game_id, name, logo, price, draw_number, draw_date, jackpot, price_type, prize_name } = game;
	const drawDate = typeof draw_date === 'string' ? new Date(draw_date) : draw_date;
	const ticketsAmountInGameset = GAMESET_TICKETS_AMOUNT_MAP[game_id] ?? 0;
	const ticketsAmount = setsNumber * ticketsAmountInGameset;

	const counterVariant: Record<string, ICounterProps['variant']> = {
		bet_cl_649: 'blue2',
	};

	const buyBtnVariant: Record<string, IBuyButtonProps['variant']> = {
		bet_cl_649: 'blue',
	};

	const handleBuyClick = () => {
		onBuy(setsNumber, game_id);
	};

	const handleSetsNumberChange = (value: number) => {
		onSetNumberChange(value, game_id);
	};

	const maxBuyTicket = () => {
		let maxAmount;
		if (game_id === 'bet_cl_telebingo') {
			maxAmount = 20;
		} else if (game_id === 'bet_cl_649') {
			maxAmount = 16;
		} else {
			maxAmount = 100;
		}

		return maxAmount;
	};

	return (
		<article className={cn(className, styles.root, styles[game_id])}>
			<header className={styles.header}>
				<h2 className={styles.title}>
					{/* @ts-ignore */}
					<Trans i18nKey={`gameCard:setTitle.${game_id}`} />
				</h2>
				<img src={logo} alt={name} loading="lazy" className={styles.logo} />
			</header>

			<div className={styles.main}>
				<h3 className={styles.prizeName}>{prize_name}</h3>
				<p className={styles.price}>
					{priceFormatter.format(moneyFromMDE(jackpot))} <span className={styles.currencySign}>₸</span>
				</p>
				<DrawInfo className={styles.drawInfo} date={drawDate} drawNumber={draw_number} />
			</div>

			<footer className={styles.footer}>
				<div className={styles.counterBlock}>
					<p className={styles.setsNumber}>{t('setsNumber')}</p>
					<Counter
						className={styles.counter}
						value={setsNumber}
						min={1}
						max={maxBuyTicket()}
						variant={counterVariant[game_id]}
						onChange={handleSetsNumberChange}
					/>
				</div>

				<div className={styles.buyBlock}>
					<p className={styles.setsInfo}>
						{t(`setsInfo_${price_type}`, {
							setsNumber: ticketsAmount,
							price: priceFormatter.format(moneyFromMDE(price)),
						})}
					</p>

					<BuyButton
						value={moneyFromMDE(price * ticketsAmount)}
						className={styles.buyButton}
						onClick={handleBuyClick}
						variant={buyBtnVariant[game_id]}
					/>
				</div>
			</footer>
		</article>
	);
};
