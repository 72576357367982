import { KeyboardEvent } from 'react';
import { PatternFormat, OnValueChange } from 'react-number-format';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from './PhoneInput.module.scss';
import { noop } from '../../../../../../utils/noop';

const FORMAT = '+7 ### ### ## ##';

interface IProps {
	className?: string;
	value: string;
	disabled?: boolean;
	onChange?: (value: string) => void;
	submitKey: () => void;
}

export function PhoneInput({ className, submitKey, value, onChange = noop, disabled }: IProps) {
	const { t } = useTranslation();

	const handleValueChange: OnValueChange = ({ value }) => {
		onChange(value);
	};

	const handleClearClick = () => {
		onChange('');
	};

	const handleKeyDown = (evt: KeyboardEvent) => {
		const { key } = evt;

		if (key === 'Enter') {
			submitKey();
		}
	};

	return (
		<div className={cn(className, styles.root)}>
			<PatternFormat
				id="phone"
				autoComplete="off"
				allowEmptyFormatting
				className={styles.input}
				inputMode="tel"
				format={FORMAT}
				value={value}
				onValueChange={handleValueChange}
				onKeyDown={handleKeyDown}
			/>

			<label className={styles.label} htmlFor="phone">
				{t('registration.phoneForm.input.label')}
			</label>

			{value && (
				<button
					className={styles.clear}
					type="button"
					aria-label="Очистить"
					onClick={handleClearClick}
					disabled={disabled}
				/>
			)}
		</div>
	);
}
