import { createAsyncThunk } from '@reduxjs/toolkit';
import { IOrderDetailsReport, submitOrderDetailsReport } from '../../api/submitOrderDetailsReport';
import { saveSubmittedReportID } from '../../utils/orderStorage';

export const submitOrderDetailsReportThunk = createAsyncThunk(
	'order/submitOrderDetailsReport',
	async (order: IOrderDetailsReport) => {
		try {
			await submitOrderDetailsReport(order);
			saveSubmittedReportID(order.orderID);
		} catch (error) {
			console.error(error);
		}
	},
);
