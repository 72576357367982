import { AxiosResponse } from 'axios';
import { microserviceInstance } from './instance';
import { ILotteryAPIGame } from '../interfaces/ILotteryAPIGame';

/**
 * Лотерейный модуль API
 * 4.5. Список игр. Метод /games
 */
export const getLotteryAPIGames = async (): Promise<AxiosResponse<ILotteryAPIGame[]>> => {
	return microserviceInstance.post<ILotteryAPIGame[]>('/games', { app_id: 3 });
};
