import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from './SMSConfirmationCodeForm.module.scss';
import { CodeInput } from './components/CodeInput';

import { noop } from '../../../../utils/noop';

interface IProps {
	className?: string;
	error?: boolean;
	phone: string;
	timeout: number;
	onSubmit?: (value: string) => void;
	onRetry?: () => void;
}

export function SMSConfirmationCodeForm({
	className,
	timeout,
	error = false,
	phone,
	onSubmit = noop,
	onRetry = noop,
}: IProps) {
	const { t } = useTranslation();
	const [code, setCode] = useState<string[]>(new Array(4).fill(''));
	const isTimeoutRun = timeout > 0;

	const handleComplete = () => {
		onSubmit(code.join(''));
	};

	return (
		<form className={cn(styles.root, className)}>
			<p className={styles.cta}>
				<Trans i18nKey="registration.smsForm.cta" values={{ phone }} />
			</p>

			<CodeInput
				className={styles.input}
				value={code}
				onChange={setCode}
				onComplete={handleComplete}
				error={error}
			/>

			<button type="button" className={styles.retry} disabled={isTimeoutRun} onClick={onRetry}>
				{t('registration.smsForm.retry')}
			</button>

			{isTimeoutRun && <p className={styles.after}>{t('registration.smsForm.after', { value: timeout })}</p>}
		</form>
	);
}
