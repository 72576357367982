import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { dateFormatter, priceFormatter, timeFormatter } from './utils';

import styles from './PurchaseDetails.module.scss';
import { GamePict } from '../../../components/GamePict';

interface IOrderDetailsProp {
	gameID: string;
	gameName: string;
	orderID: string;
	drawNumber?: number;
	drawDate?: Date;
	purchaseDate: Date;
	ticketsAmount: number;
	totalPrice: number;
}

export interface IPurchaseDetailsProps {
	className?: string;
	order: IOrderDetailsProp;
}

export function PurchaseDetails({ className, order }: IPurchaseDetailsProps) {
	const { t } = useTranslation();
	const { gameID, gameName, drawDate, drawNumber, orderID, purchaseDate, ticketsAmount, totalPrice } = order;

	return (
		<div className={cn(className, styles.root)}>
			<div className={styles.block1}>
				<GamePict className={styles.pict} id={gameID} name={gameName} />

				<div>
					{drawNumber !== undefined && (
						<p className={styles.title}>{t('purchaseDetails.drawNumber', { count: drawNumber })}</p>
					)}

					{drawDate && (
						<p className={styles.drawDate}>
							{t('purchaseDetails.drawDate', {
								date: dateFormatter.format(drawDate),
								time: timeFormatter.format(drawDate),
							})}
						</p>
					)}

					<p className={styles.orderNumber}>{t('purchaseDetails.orderID', { id: orderID })}</p>
				</div>
			</div>

			<dl className={styles.block2}>
				<div className={styles.listRecord}>
					<dt className={styles.dt}>{t('purchaseDetails.purchaseDate')}</dt>
					<dd className={styles.dd}>{dateFormatter.format(purchaseDate)}</dd>
				</div>

				<div className={styles.listRecord}>
					<dt className={styles.dt}>{t('purchaseDetails.ticketsAmount', { count: ticketsAmount })}</dt>
					<dd className={styles.dd}>{priceFormatter.format(totalPrice)} ₸</dd>
				</div>
			</dl>
		</div>
	);
}
