/* eslint-disable prettier/prettier */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'intl-pluralrules';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './i18n/config';
import { store } from './store/store';
import './styles/styles.scss';
import './static/fonts/Inter/Inter-Bold.ttf';
import './static/fonts/Inter/Inter-SemiBold.ttf';
import './static/fonts/Inter/Inter-Medium.ttf';
import './static/fonts/Inter/Inter-Black.ttf';
import './static/fonts/Inter/Inter-Regular.ttf';
import { ErrorBoundary } from './components/ErrorBoundary';
import { App } from './widgets/App';
import { telegram } from './utils/telegram';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
console.log('Bot API version', telegram.version);

root.render(
	// <React.StrictMode>
	// </React.StrictMode>,
	<ErrorBoundary>
		<Provider store={store}>
			<App />
		</Provider>

		<ToastContainer position='top-center' hideProgressBar />
	</ErrorBoundary>
);
