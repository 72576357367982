/* eslint-disable func-names */
import axios, { AxiosInstance, AxiosRequestTransformer } from 'axios';
import sha1 from 'crypto-js/hmac-sha1';
import { telegram } from '../utils/telegram';
import { TELEGRAM_LANG_KAZAKH } from '../constants';

const platformSecretKey = process.env.REACT_APP_SECRET_KEY ?? '';
const microserviceSecretKey = process.env.REACT_APP_MICROSERVICE_SECRET_KEY ?? '';
const isFakeAPI = process.env.REACT_APP_FAKE_API === 'true';

if (isFakeAPI) {
	console.log('The app uses a fake API');
} else {
	console.log('The app uses a real API');
}

const createAxiosRequestTransformer = (secretKey: string): AxiosRequestTransformer =>
	function (data, headers) {
		const body = JSON.stringify(data ?? {});
		const timestamp = Math.floor(Date.now() / 1000);
		const sign = sha1(body + timestamp, secretKey).toString();

		headers['X-Timestamp'] = timestamp;
		headers['X-Sign'] = sign;

		return body;
	};

const createHeaders = (merchantID: string) => {
	const userLang = telegram.initDataUnsafe.user?.language_code === TELEGRAM_LANG_KAZAKH ? 'kz' : 'ru';

	return {
		Authorization: 'Basic YWRtaW46YWRtaW4=',
		'Content-Type': 'application/json',
		'X-Merchant-Id': merchantID,
		'Accept-Language': `${userLang}, kz;q=0.9, *;q=0.8`,
		Lang: userLang,
	};
};

export const platformInstance = axios.create({
	baseURL: process.env.REACT_APP_PLATFORM_BASE_URL,
	headers: createHeaders(process.env.REACT_APP_X_MERCHANT_ID ?? ''),
	transformRequest: createAxiosRequestTransformer(platformSecretKey),
});

export const microserviceInstance = axios.create({
	baseURL: process.env.REACT_APP_MICROSERVICE_BASE_URL,
	headers: createHeaders(process.env.REACT_APP_MICROSERVICE_X_MERCHANT_ID ?? ''),
	transformRequest: createAxiosRequestTransformer(microserviceSecretKey),
});

export const botInstance = axios.create({
	baseURL: process.env.REACT_APP_BOT_BACKEND_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
		dsa: 'dsa',
	},
});

const setInstanceLang = (instance: AxiosInstance, lang: string) => {
	instance.defaults.headers.Lang = lang;
	instance.defaults.headers['Accept-Language'] = `${lang}, kz;q=0.9, *;q=0.8`;
};

export const setAPIInstancesLang = (lang: string) => {
	[platformInstance, microserviceInstance].forEach((inst) => {
		setInstanceLang(inst, lang);
	});
};
