import { ComponentPropsWithoutRef, KeyboardEvent } from 'react';
import cn from 'classnames';

import styles from './CharInput.module.scss';

import { noop } from '../../../../../../../utils/noop';

// check for special keys or special key combination (CTRL + v, CTRL + r (reload page))
const checkSpecialKey = (evt: KeyboardEvent): boolean => {
	const { key, metaKey } = evt;
	return /Backspace|Tab|ArrowLeft|ArrowRight|ArrowUp|ArrowDown|Delete/.test(key) || (metaKey && /r|v/.test(key));
};

export interface ICharInputProps extends ComponentPropsWithoutRef<'input'> {
	index: number;
	error?: boolean;
	inputPattern?: RegExp;
	onValueChange?: (value: string, index: number) => void;
	onBackspaceClick?: (index: number) => void;
}

export function CharInput({
	className,
	index,
	error = false,
	inputPattern = /[0-9]/,
	onValueChange = noop,
	onBackspaceClick = noop,
	...props
}: ICharInputProps) {
	const handleKeyDown = (evt: KeyboardEvent) => {
		const { key } = evt;

		if (key === 'Backspace') {
			evt.preventDefault(); // предотвращаем генерирование события onChange
			onBackspaceClick(index);
			return;
		}

		if (!inputPattern.test(key) && !checkSpecialKey(evt)) {
			evt.preventDefault();
		}

		if (inputPattern.test(key)) {
			evt.preventDefault();
			onValueChange(key, index);
		}
	};

	return (
		<input
			{...props}
			className={cn(className, styles.root, error && styles.error)}
			type="text"
			onKeyDown={handleKeyDown}
			onChange={noop}
		/>
	);
}
