import { AxiosResponse } from 'axios';
import { IWithAPIError } from '../interfaces/IAPIError';
import { microserviceInstance } from './instance';

const isFakeAPI = process.env.REACT_APP_FAKE_API === 'true';

interface ISMSVerificationCode extends IWithAPIError {
	code?: string;
	error?: {
		code: number;
		message: string;
	};
}

/**
 * Лотерейный модуль API
 * 4.1. Получение кода для СМС-верификация. Метод /sms
 */
export const getSMSVerificationCode = async (phone: string): Promise<AxiosResponse<ISMSVerificationCode>> => {
	if (!isFakeAPI) {
		return microserviceInstance.post<ISMSVerificationCode>('/sms', { player_phone: phone });
	}

	return new Promise((resolve) => {
		setTimeout(() => {
			resolve({
				data: {
					code: '1234',
				},
			} as unknown as AxiosResponse<ISMSVerificationCode>);
		}, 1000);
	});
};
