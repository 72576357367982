import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './Layout.module.scss';
import { Loader } from '../components/Loader';
import { useAppSelector } from '../store';
import { selectLoading } from '../store/appSlice';

interface IProps {
	children: ReactNode;
	className?: string;
}

export function Layout({ children, className }: IProps) {
	const loading = useAppSelector(selectLoading);

	return (
		<main className={cn(styles.root, className)}>
			{children}
			{loading && <Loader />}
		</main>
	);
}

export default Layout;
