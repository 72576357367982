import { generateSet, pickRandomUniqueNumbers } from './utils';

// массив из 8 чисел, где первые 4 числа из первого поля (1-20), вторые 4 числа из второго (1-20)
export const generate420bet = (): number[] => {
	const set1 = generateSet(1, 20);
	const set2 = generateSet(1, 20);

	const pickGroup1 = pickRandomUniqueNumbers(set1, 4);
	const pickGroup2 = pickRandomUniqueNumbers(set2, 4);

	return [...pickGroup1, ...pickGroup2];
};
