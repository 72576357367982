import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { noop } from '../../../../utils/noop';

import styles from './PhoneForm.module.scss';
import { Button } from '../../../../components/Button';
import { PhoneInput } from './components/PhoneInput';
import { Checkbox } from '../../../../components/Checkbox';

import { useAppSelector } from '../../../../store';
import { selectLanguage } from '../../../../store/appSlice';

interface IProps {
	className?: string;
	disabled?: boolean;
	onSubmit?: (value: string) => void;
}

export function PhoneForm({ className, disabled, onSubmit = noop }: IProps) {
	const { t } = useTranslation();
	const [phone, setPhone] = useState('');
	// всего 11 цифр, но в стейте первая цифра 7 не используется, поскольку зашита в формате инпута
	const isPhoneComplete = phone.length === 10;
	const [isOfertaChecked, setIsOfertaChecked] = useState(true);
	const lang = useAppSelector(selectLanguage);

	const ofertaHref =
		lang === 'kz' ? 'https://static.sz.kz/docs/oferta_kaz.pdf' : 'https://static.sz.kz/docs/oferta_rus.pdf';

	const rullesHref =
		lang === 'kz' ? 'https://static.sz.kz/docs/cash_rules_kaz.pdf' : 'https://static.sz.kz/docs/cash_rules_rus.pdf';

	const handleSubmit = () => {
		onSubmit('7' + phone);
	};

	const handleEnterSubmit = () => {
		if (!disabled && isPhoneComplete && isOfertaChecked) {
			onSubmit('7' + phone);
		}
	};

	const handleOfertaChange = () => {
		setIsOfertaChecked(!isOfertaChecked);
	};

	const ofertaLink = (
		// eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
		<a href={ofertaHref} className={styles.ofertaLink} target="_blank" rel="noreferrer" />
	);

	const rullesLink = (
		// eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
		<a href={rullesHref} className={styles.ofertaLink} target="_blank" rel="noreferrer" />
	);
	return (
		<form
			className={className}
			onSubmit={(e) => {
				e.preventDefault();
			}}
		>
			<p className={styles.cta}>
				<Trans i18nKey="registration.phoneForm.cta" />
			</p>

			<PhoneInput
				className={styles.input}
				submitKey={handleEnterSubmit}
				value={phone}
				onChange={setPhone}
				disabled={disabled}
			/>

			<Checkbox
				className={styles.ofertaCheckbox}
				label={
					<Trans
						i18nKey="registration.phoneForm.ofertaLabel"
						components={{
							Link: ofertaLink,
							Href: rullesLink,
						}}
					/>
				}
				checked={isOfertaChecked}
				onChange={handleOfertaChange}
			/>

			<Button
				className={styles.submitBtn}
				fullwidth
				disabled={disabled || !isPhoneComplete || !isOfertaChecked}
				onClick={handleSubmit}
			>
				{t('registration.phoneForm.submitBtn')}
			</Button>
		</form>
	);
}
