import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { GamesCatalog } from '../../pages/GamesCatalog';
import { PaymentPage } from '../../pages/PaymentPage';
import { PurchaseStatusPage } from '../../pages/PurchaseStatusPage';
import { RegistrationPage } from '../../pages/RegistrationPage';

import { Routes as AppRoute } from '../../enums/routes.enum';
import { useAppDispatch, initAppThunk, useAppSelector } from '../../store';
import { selectAppReady } from '../../store/appSlice';

export const App = () => {
	const dispatch = useAppDispatch();
	const isAppReady = useAppSelector(selectAppReady);

	useEffect(() => {
		dispatch(initAppThunk());
	}, [dispatch]);

	if (!isAppReady) return null;

	return (
		<BrowserRouter>
			<Routes>
				<Route path={AppRoute.Home} element={<GamesCatalog />} />
				<Route path={AppRoute.Registration} element={<RegistrationPage />} />
				<Route path={AppRoute.PaymentSelect} element={<PaymentPage />} />
				<Route path={AppRoute.Purchase} element={<PurchaseStatusPage />} />
			</Routes>
		</BrowserRouter>
	);
};
