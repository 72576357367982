/**
 * Генерирует случайное целое число из заданного диапазона
 * @param min первое число диапазона (включительно)
 * @param max последнее число диапазона (включительно)
 */
export const randomInteger = (min: number, max: number) => {
	const random = min + Math.random() * (max - min + 1);
	return Math.floor(random);
};

/**
 * Генерирует массив целых чисел в указанном диапазоне
 * @param min первое число диапазона (включительно)
 * @param max последнее число диапазона (включительно)
 */
export const generateSet = (min: number, max: number) =>
	Array(max - min + 1)
		.fill(0)
		.map((_, index) => min + index);

/**
 * Генерирует массив уникальных целых чисел в указанном диапазоне, отсортированный по возрастанию
 * @param min первое число диапазона (включительно)
 * @param max последнее число диапазона (включительно)
 * @param count количество уникальных чисел в указанном диапазоне (длина конечного массива)
 */
export const getRandomUniqueNumbersInRange = (min: number, max: number, count: number) => {
	const uniqueNumbers: number[] = [];

	// Генерируем случайные числа, пока не достигнем нужного количества
	while (uniqueNumbers.length < count) {
		// Генерируем случайное число в указанном диапазоне
		const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

		// Проверяем, является ли сгенерированное число уникальным
		if (uniqueNumbers.indexOf(randomNumber) === -1) {
			uniqueNumbers.push(randomNumber);
		}
	}

	uniqueNumbers.sort();

	return uniqueNumbers;
};

/**
 * Возвращает новый массив с перемешенными случайным образом элементами
 * @param array исзодный массив
 */
export const shuffle = (array: number[]) => {
	const copyArr = array.slice();

	for (let i = copyArr.length - 1; i > 0; i--) {
		const index = Math.floor(Math.random() * i);
		const tmp = copyArr[index];
		copyArr[index] = copyArr[i];
		copyArr[i] = tmp;
	}

	return copyArr;
};

/**
 * Создает новый массив указанного размера из уникальных чисел, произольно выбранных из заданного массива
 * @param set исходный массив чисел
 * @param count количество чисел возвращаемого массива
 */
export const pickRandomUniqueNumbers = (set: number[], count: number) => {
	const shuffleArr = shuffle(set);
	return shuffleArr.slice(0, count);
};
