import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { registrationSlice } from './registrationSlice';
import { catalogSlice } from './catalogSlice';
import { orderSlice } from './order/orderSlice';
import { appSlice } from './appSlice';

export const store = configureStore({
	reducer: {
		[catalogSlice.name]: catalogSlice.reducer,
		[registrationSlice.name]: registrationSlice.reducer,
		[orderSlice.name]: orderSlice.reducer,
		[appSlice.name]: appSlice.reducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
