import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { DEFAUL_LANG } from '../constants';

interface AppState {
	loading: boolean;
	ready: boolean;
	language: string;
}

const initialState: AppState = {
	loading: false,
	ready: false,
	language: DEFAUL_LANG,
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<AppState['loading']>) => {
			state.loading = action.payload;
		},
		setAppReady: (state, action: PayloadAction<AppState['ready']>) => {
			state.ready = action.payload;
		},
		setLanguage: (state, action: PayloadAction<AppState['language']>) => {
			state.language = action.payload;
		},
	},
});

export const { setAppReady, setLanguage, setLoading } = appSlice.actions;

export const selectLoading = (state: RootState) => state.app.loading;
export const selectAppReady = (state: RootState) => state.app.ready;
export const selectLanguage = (state: RootState) => state.app.language;
export default appSlice.reducer;
