import { generateSet, pickRandomUniqueNumbers } from './utils';

// массив из 9 чисел, где первые 8 чисел из большого поля (1-20), а последнее число из маленького поля (1-4)
export const generate820bet = (): number[] => {
	const set1 = generateSet(1, 20);
	const set2 = generateSet(1, 4);

	const pickGroup1 = pickRandomUniqueNumbers(set1, 8);
	const pickGroup2 = pickRandomUniqueNumbers(set2, 1);

	return [...pickGroup1, ...pickGroup2];
};
