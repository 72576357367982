import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { isAxiosError } from 'axios';
import { getGames } from '../api/getGames';
import { setGameSets, setGameTickets } from './catalogSlice';
import { setLoading } from './appSlice';
import { IGame, IGameSet, ILotteryAPIGame } from '../interfaces';
import { lotteryGameIDMap, renameGames } from '../constants';
import { telegram } from '../utils/telegram';
import { getLotteryAPIGames } from '../api/getLotteryAPIGames';

// Комбинируем данные игр из двух API таким образом, что цена билета берется из лотерейного модуля
const combineGames = (applicationAPIGames: IGame[], lotteryAPIGames: ILotteryAPIGame[]): IGame[] => {
	return applicationAPIGames.map((game) => {
		const applicationGameID = game.game_id;
		const lotteryGameID = lotteryGameIDMap[applicationGameID];
		const lotteryGame = lotteryAPIGames.find((it) => it.game_id === lotteryGameID);

		if (!lotteryGame) {
			throw new Error(
				`No game found in the Lottery API with ID=${lotteryGameID}, corresponding game from the Application API with ID=${applicationGameID}`,
			);
		}

		const price = lotteryGame.ticket_price;

		return {
			...game,
			price,
		};
	});
};

export const fetchGames = createAsyncThunk('catalog/fetchGames', async (_, { dispatch }) => {
	dispatch(setLoading(true));

	try {
		const [applicationAPIResponse, lotteryAPIResponse] = await Promise.all([getGames(), getLotteryAPIGames()]);
		const { data: applicationAPIGames } = applicationAPIResponse;
		const { data: lotteryAPIGames } = lotteryAPIResponse;
		const games = combineGames(applicationAPIGames, lotteryAPIGames);

		const gamesets: IGameSet[] = games.map((game) => ({
			game,
			amount: 1,
		}));

		const gametickets: IGameSet[] = games.map((game) => ({
			game,
			amount: 1,
		}));

		dispatch(setGameSets(gamesets));
		dispatch(setGameTickets(gametickets));

		return games;
	} catch (error) {
		const errMessage =
			isAxiosError(error) && error.code === 'ERR_NETWORK'
				? i18next.t('common.networkError')
				: i18next.t('common.error');

		telegram.showAlert(errMessage);
		console.error(error);
		return [];
	} finally {
		dispatch(setLoading(false));
	}
});
