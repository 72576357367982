import pict420 from './picts/pict_420.svg';
import pict536 from './picts/pict_536.svg';
import pict649 from './picts/pict_649.svg';
import pict777 from './picts/pict_777.svg';
import pict820 from './picts/pict_820.svg';
import pictKENO from './picts/pict_KENO.svg';
import pictKENO2 from './picts/pict_KENO2.svg';
import pictTelebingo from './picts/pict_telebingo.svg';

interface IProps {
	className?: string;
	id: string;
	name: string;
}

/* eslint-disable prettier/prettier */
const src: Record<string, string> = {
	'bet_sz_420': pict420,
	'bet_cl_536': pict536,
	'bet_cl_649': pict649,
	'bet_cl_777': pict777,
	'bet_sz_820': pict820,
	'bet_cl_keno': pictKENO,
	'bet_cl_keno2': pictKENO2,
	'bet_cl_telebingo': pictTelebingo,
};
/* eslint-disable prettier/prettier */

export function GamePict({ id, name, className }: IProps) {
	return (
		<img className={className} src={src[id]} alt={name} />
	)
}
