import { AxiosResponse } from 'axios';
import { IGameSet, ILotteryAPIOrderStatus, IOrderDetails } from '../../interfaces';
import {
	composePurchaseDetailsFromFailedOrder,
	composePurchaseDetailsFromLotteryModuleOrder,
} from '../../pages/PurchaseStatusPage/utils';

// const allTicketsHaveNumbers = (tickets: Required<ILotteryAPIOrderStatus>['ticket_data']) => {
// 	return tickets.every((ticket) => ticket.ticket_number);
// };

const ticketsCountMatches = (orderStatus: ILotteryAPIOrderStatus) => {
	const { ticket_count, ticket_data } = orderStatus;
	return ticket_data && ticket_data.length === ticket_count;
};

type Fetcher = (orderID: string) => Promise<AxiosResponse<ILotteryAPIOrderStatus>>;

export const getCompleteOrderDetails = (
	orderID: string,
	order: IGameSet,
	statusFetcher: Fetcher,
	fetchInterval = 5000,
) => {
	let timeoutID: number;
	let iteration = 0;

	const result: Promise<IOrderDetails> = new Promise((resolve, reject) => {
		const fetchStatus = async () => {
			try {
				const res = await statusFetcher(orderID);
				const { data: orderStatus } = res;
				const { payment_status, ticket_data, error } = orderStatus;

				const repeatFetch = () => {
					iteration++;
					timeoutID = window.setTimeout(fetchStatus, fetchInterval);
				};

				const requestsLimitReached = iteration >= 2;

				if (error) {
					if (!requestsLimitReached && error.code === 2 && payment_status === 2) {
						repeatFetch();
					} else {
						const orderDetails = composePurchaseDetailsFromFailedOrder(orderStatus, order, 'error');
						resolve(orderDetails);
					}
				} else if (payment_status !== 1 && payment_status !== 2) {
					const orderDetails = composePurchaseDetailsFromFailedOrder(orderStatus, order, 'payment_status');
					resolve(orderDetails);
				} else if (payment_status === 2) {
					if (requestsLimitReached) {
						const orderDetails = composePurchaseDetailsFromFailedOrder(
							orderStatus,
							order,
							'payment_status',
						);

						resolve(orderDetails);
					} else {
						repeatFetch();
					}
					//	Убрано условие проверки, что во всех тикетах есть number
					//	} else if (ticket_data && allTicketsHaveNumbers(ticket_data) && ticketsCountMatches(orderStatus)) {
				} else if (ticket_data && ticketsCountMatches(orderStatus)) {
					const orderDetails = composePurchaseDetailsFromLotteryModuleOrder(orderStatus, order);
					resolve(orderDetails);
				} else if (requestsLimitReached) {
					const status: IOrderDetails['status'] = ticketsCountMatches(orderStatus)
						? 'success'
						: 'tickets_count_mismatched';

					const orderDetails =
						status === 'success'
							? composePurchaseDetailsFromLotteryModuleOrder(orderStatus, order)
							: composePurchaseDetailsFromFailedOrder(orderStatus, order, 'tickets_count_mismatched');

					resolve(orderDetails);
				} else {
					repeatFetch();
				}
			} catch (error) {
				reject(error);
			}
		};

		fetchStatus();
	});

	return {
		abort: () => {
			if (timeoutID) {
				clearTimeout(timeoutID);
			}
		},
		result,
	};
};
