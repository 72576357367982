import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources, defaultNS } from './resources';
import { DEFAUL_LANG, TELEGRAM_LANG_KAZAKH } from '../constants';
import { telegram } from '../utils/telegram';

i18next.use(initReactI18next).init({
	lng: telegram.initDataUnsafe.user?.language_code === TELEGRAM_LANG_KAZAKH ? 'kz' : DEFAUL_LANG,
	debug: true,
	fallbackLng: DEFAUL_LANG,
	defaultNS,
	resources,
});
