import { lotteryGameIDMap } from '../../constants';
import { IGame } from '../../interfaces';
import {
	generate420bet,
	generate536bet,
	generate649bet,
	generate777bet,
	generate820bet,
	generateKENOBet,
	generateTelebingoBet,
} from '../../utils/bets';
import { ILotteryAPITicketsOrder } from '../../api/submitOrderLotteryAPI';

export const getOrderAPIGameID = (gameID: IGame['game_id']) => lotteryGameIDMap[gameID];

export const generateLotteryAPIBets = (
	gameID: string,
): ILotteryAPITicketsOrder['ticket_info'][number]['prognosis_bet'] => {
	switch (gameID) {
		case 'bet_sz_420':
			return generate420bet();
		case 'bet_sz_820':
			return generate820bet();
		case 'bet_cl_649':
			return generate649bet();
		case 'bet_cl_keno':
		case 'bet_cl_keno2':
			return generateKENOBet();
		case 'bet_cl_536':
			return generate536bet();
		case 'bet_cl_777':
			return generate777bet();
		case 'bet_cl_telebingo':
			return generateTelebingoBet();
		default:
			return [];
	}
};
