import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Layout } from '../../layouts';
import { Header } from '../../widgets/Header';
import { PhoneForm } from './components/PhoneForm';
import { SMSConfirmationCodeForm } from './components/SMSConfirmationCodeForm';

import { useAppDispatch, useAppSelector } from '../../store';
import {
	resetRegistrationState,
	selectPhone,
	selectSubmitPhoneStatus,
	selectVerificationCode,
	setCode,
	setPhone,
} from '../../store/registrationSlice';
import { selectLoading } from '@/store/appSlice';
import { getSMSVerificationCodeThunk } from '../../store/getSMSVerificationCodeThunk';
import { purchaseTicketsThunk, selectOrder, selectPaymentMethod } from '../../store/order';
import { telegram } from '../../utils/telegram';
import { useCountdown } from './useCountdown';
import { useReloadApp } from '../../utils/useReloadApp';

type PageMode = 'phone' | 'sms';

const TIMEOUT = 60;

export function RegistrationPage() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [mode, setMode] = useState<PageMode>('phone');
	const userPhone = useAppSelector(selectPhone);
	const verificationCode = useAppSelector(selectVerificationCode);
	const submitPhoneStatus = useAppSelector(selectSubmitPhoneStatus);
	const order = useAppSelector(selectOrder);
	const paymentMethodID = useAppSelector(selectPaymentMethod);
	const getAppLoading = useAppSelector(selectLoading);
	const [isCodeWrong, setIsCodeWrong] = useState(false);

	const title = mode === 'phone' ? t('registration.phoneForm.title') : t('registration.smsForm.title');
	const closable = mode === 'phone';

	const { countdownValue, setCountdown, resetCountdown } = useCountdown(0);

	// при нажатии «перезагрузить страницу» в меню Телеграма грузим приложение заново
	useReloadApp(() => paymentMethodID == null);

	useEffect(() => {
		if (verificationCode) {
			setMode('sms');
			setCountdown(TIMEOUT);
		}
	}, [setCountdown, verificationCode]);

	const handleBackClick = () => {
		if (mode === 'sms') {
			setMode('phone');
			dispatch(setCode(null));
			setCountdown(0);
			setIsCodeWrong(false);
		} else {
			resetRegistrationState();
			navigate(-1);
		}
	};

	const handlePhoneSubmit = (phone: string) => {
		dispatch(setPhone(phone));
		dispatch(getSMSVerificationCodeThunk(phone));
	};

	const handleCodeSubmit = (code: string) => {
		if (code !== verificationCode) {
			setIsCodeWrong(true);

			window.setTimeout(() => {
				try {
					// Иногда возникает ошибка WebAppPopupOpened.
					telegram.showPopup({
						title: t('registration.popup.wrongCode.title'),
						message: t('registration.popup.wrongCode.message'),
					});
				} catch (error) {
					console.error(error);
				}
			}, 0);

			return;
		}

		if (!order || paymentMethodID == null || !userPhone) {
			console.error('Missing "order" or "paymentMethodID" or "userPhone"');
			return;
		}

		if (!getAppLoading) {
			dispatch(
				purchaseTicketsThunk({
					gameset: order,
					paymentMethodID,
					phone: userPhone,
				}),
			);
		}
	};

	const handleRetrySendSMS = () => {
		if (userPhone) {
			dispatch(getSMSVerificationCodeThunk(userPhone));
			resetCountdown();
		}
	};

	return (
		<Layout>
			<Header title={title} closable={closable} back onBackClick={handleBackClick} />

			{mode === 'phone' ? (
				<PhoneForm onSubmit={handlePhoneSubmit} disabled={submitPhoneStatus === 'pending'} />
			) : (
				<SMSConfirmationCodeForm
					onSubmit={handleCodeSubmit}
					phone={userPhone ?? ''}
					onRetry={handleRetrySendSMS}
					timeout={countdownValue}
					error={isCodeWrong}
				/>
			)}
		</Layout>
	);
}
