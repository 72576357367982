import { AxiosResponse } from 'axios';
import { microserviceInstance } from './instance';
import { IPaymentMethod } from '../interfaces';

interface IPaymentMethodList {
	ButtonList: IPaymentMethod[];
}

/**
 * Лотерейный модуль API
 * 4.2. Получение списка методов платежа. Метод /list
 */
export const getPaymentMethods = async (): Promise<AxiosResponse<IPaymentMethodList>> => {
	return microserviceInstance.post<IPaymentMethodList>('/list');
};
