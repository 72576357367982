import { ComponentPropsWithRef } from 'react';
import cn from 'classnames';

import styles from './Button.module.scss';
import { ReactComponent as PhoneIcon } from './icons/phone.svg';
import { ReactComponent as CardIcon } from './icons/card.svg';

interface IProps extends ComponentPropsWithRef<'button'> {
	fullwidth?: boolean;
	icon?: 'phone' | 'card';
	variant?: 'white' | 'yellow';
}

export function Button({
	className,
	children,
	type = 'button',
	icon,
	variant = 'yellow',
	fullwidth,
	...props
}: IProps) {
	let Icon;

	switch (icon) {
		case 'card':
			Icon = CardIcon;
			break;
		case 'phone':
			Icon = PhoneIcon;
			break;
		default:
			break;
	}

	return (
		<button
			{...props}
			// eslint-disable-next-line react/button-has-type
			type={type}
			className={cn(className, styles.root, fullwidth && styles.fullwidth, variant === 'white' && styles.white)}
		>
			{Icon && <Icon />}
			{children}
		</button>
	);
}
