import { AxiosResponse } from 'axios';
import { botInstance } from './instance';
import { TELEGRAM_LANG_KAZAKH } from '../constants';

const isFakeAPI = process.env.REACT_APP_FAKE_API === 'true';

interface ILang {
	lang_code: string;
}

/**
 * Proxy API
 */
export const getLanguage = async (userID: string): Promise<AxiosResponse<ILang>> => {
	if (isFakeAPI) {
		return {
			data: {
				lang_code: TELEGRAM_LANG_KAZAKH,
			},
		} as unknown as AxiosResponse<ILang>;
	}

	return botInstance.post<ILang>('/user_lang', { user_id: userID });
};
