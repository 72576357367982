import { useEffect, useState, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from './DrawInfo.module.scss';
import { useFormatTimeLeft } from './useFormatTimeLeft';

export interface IDrawInfoProps {
	className?: string;
	date: Date;
	drawNumber: number;
	style?: CSSProperties;
}

export function DrawInfo({ className, date: drawDate, drawNumber, style }: IDrawInfoProps) {
	const { t } = useTranslation();
	const [nowDate, setNowDate] = useState(new Date());

	const formattedTimeLeft = useFormatTimeLeft(nowDate, drawDate);
	const formattedCirculation = `${t('drawInfo.number', { number: drawNumber })} ${formattedTimeLeft}`;

	useEffect(() => {
		const timer = window.setInterval(() => {
			setNowDate(new Date());
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, []);

	return (
		<p className={cn(className, styles.root)} style={style}>
			{formattedCirculation}
		</p>
	);
}
