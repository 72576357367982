import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { priceFormatter } from '../../utils';

import styles from './BuyButton.module.scss';

export interface IBuyButtonProps {
	className?: string;
	value: number;
	disabled?: boolean;
	variant?: 'gold' | 'blue';
	onClick?: () => void;
}

export function BuyButton({ className, value, variant = 'gold', disabled, onClick }: IBuyButtonProps) {
	const { t } = useTranslation('gameCard');

	return (
		<button
			type="button"
			className={cn(className, styles.root, variant === 'blue' && styles.blue)}
			onClick={onClick}
			disabled={disabled}
		>
			<span className={styles.buy}>{t('buyButton')}</span>
			<span className={styles.price}>{priceFormatter.format(value)} ₸</span>
		</button>
	);
}
