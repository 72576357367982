import cn from 'classnames';

import styles from './Counter.module.scss';
import { CounterButton } from '../CounterButton';

export interface ICounterProps {
	className?: string;
	value: number;
	min?: number;
	max?: number;
	variant?: 'gold1' | 'gold2' | 'blue' | 'blue2';
	onChange: (value: number) => void;
}

export function Counter({ className, value, min, max, variant = 'gold1', onChange }: ICounterProps) {
	const isMinusDisabled = min != undefined ? value <= min : false;
	const isPlusDisabled = max != undefined ? value >= max : false;

	return (
		<div className={cn(styles.root, className)}>
			<CounterButton
				className={styles.btn}
				variant="minus"
				color={variant === 'blue' || variant === 'blue2' ? 'blue' : 'gold'}
				onClick={() => onChange(value - 1)}
				disabled={isMinusDisabled}
			/>

			<p className={cn(styles.value, (variant === 'gold2' || variant === 'blue2') && styles.value_black)}>
				{value}
			</p>

			<CounterButton
				className={styles.btn}
				variant="plus"
				color={variant === 'blue' || variant === 'blue2' ? 'blue' : 'gold'}
				onClick={() => onChange(value + 1)}
				disabled={isPlusDisabled}
			/>
		</div>
	);
}
