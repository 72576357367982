import { reloadApp } from './reloadApp';

/**
 * @param condition для перезагрузки функция должна вернуть true
 */
export const useReloadApp = (condition: () => boolean) => {
	if (condition()) {
		reloadApp();
	}
};
