import mainRU from '../locales/ru/main.json';
import mainKZ from '../locales/kz/main.json';
import gameCardRU from '../locales/ru/gameCard.json';
import gameCardKZ from '../locales/kz/gameCard.json';

export const defaultNS = 'main';

export const resources = {
	ru: {
		main: mainRU,
		gameCard: gameCardRU,
	},
	kz: {
		main: mainKZ,
		gameCard: gameCardKZ,
	},
} as const;
