import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import cn from 'classnames';

import styles from './GamesCatalog.module.scss';
import { Layout } from '../../layouts';
import { GameCard } from '../../widgets/GameCardKit/GameCard';
import { GamesetCard } from '../../widgets/GameCardKit/GamesetCard';

import { Routes } from '../../enums/routes.enum';
import { fetchGames, useAppDispatch, useAppSelector } from '../../store';
import { selectGameSets, selectTickets, setGameSetAmount, setTicketsAmount } from '../../store/catalogSlice';
import { setOrder, setOrderType } from '../../store/order';
import { selectAppReady, selectLanguage } from '../../store/appSlice';

const TOAST_SELECT_SETS_ID = 'select-sets-toast';

export function GamesCatalog() {
	const { t } = useTranslation();
	const gamesets = useAppSelector(selectGameSets);
	const gamesetTelebingo = gamesets.find((it) => it.game.game_id === 'bet_cl_telebingo');
	const gameset649 = gamesets.find((it) => it.game.game_id === 'bet_cl_649');
	const gameTickets = useAppSelector(selectTickets);
	const isAppReady = useAppSelector(selectAppReady);
	const lang = useAppSelector(selectLanguage);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (isAppReady) {
			dispatch(fetchGames());
		}
	}, [dispatch, isAppReady]);

	const handleSetsAmountChange = (value: number, gameId: string) => {
		dispatch(
			setGameSetAmount({
				gameID: gameId,
				amount: value,
			}),
		);
	};

	const handleBuyGamesets = (value: number, gameId: string) => {
		const game = gamesets.find((it) => it.game.game_id === gameId)?.game;

		if (!game) {
			return;
		}

		if (value === 0) {
			toast(t('catalog.alertSelectSets'), { toastId: TOAST_SELECT_SETS_ID });
			return;
		}

		dispatch(
			setOrder({
				game,
				amount: value,
			}),
		);

		dispatch(setOrderType('gameSet'));

		navigate(Routes.PaymentSelect);
	};

	const handleTicketsAmountChange = (value: number, gameId: string) => {
		dispatch(
			setTicketsAmount({
				gameID: gameId,
				amount: value,
			}),
		);
	};

	const handleBuyTickets = (value: number, gameId: string) => {
		const game = gameTickets.find((it) => it.game.game_id === gameId)?.game;

		if (!game) {
			return;
		}

		if (value === 0) {
			toast(t('catalog.alertSelectSets'), { toastId: TOAST_SELECT_SETS_ID });
			return;
		}

		dispatch(
			setOrder({
				game,
				amount: value,
			}),
		);

		dispatch(setOrderType('gameTickets'));

		navigate(Routes.PaymentSelect);
	};

	return (
		<Layout>
			{isAppReady && (
				<>
					<nav className={styles.nav}>
						<Link
							className={cn(styles.linkBtn, styles.linkBtnFullWidth)}
							// Здесь и далее: это не ошибка — русский язык — страница kz-KZ
							to={`https://sz.kz/results?SetLanguage=${lang === 'kz' ? 'ru-RU' : 'kz-KZ'}`}
							target="_blank"
							rel="noreferrer"
						>
							{t('catalog.archive')}
						</Link>

						<Link
							className={cn(styles.linkBtn, styles.linkBtnFullWidth)}
							to={`https://sz.kz/akcii?SetLanguage=${lang === 'kz' ? 'ru-RU' : 'kz-KZ'}`}
							target="_blank"
							rel="noreferrer"
						>
							{t('catalog.akcii')}
						</Link>

						<Link
							className={cn(styles.linkBtn, styles.linkBtnFullWidth)}
							to={`https://sz.kz/chat-help?SetLanguage=${lang === 'kz' ? 'ru-RU' : 'kz-KZ'}`}
							target="_blank"
							rel="noreferrer"
						>
							{t('catalog.chat-help')}
						</Link>

						<Link
							className={cn(styles.linkBtn, styles.linkBtnArrow)}
							to={`https://sz.kz/help?SetLanguage=${lang === 'kz' ? 'ru-RU' : 'kz-KZ'}`}
							target="_blank"
							rel="noreferrer"
						>
							{t('catalog.help')}
						</Link>
					</nav>

					<h1 className={styles.title}>{t('catalog.title')}</h1>

					<div className={styles.list}>
						{gamesetTelebingo && (
							<GamesetCard
								className={styles.card}
								game={gamesetTelebingo.game}
								setsNumber={gamesetTelebingo.amount}
								onSetNumberChange={handleSetsAmountChange}
								onBuy={handleBuyGamesets}
							/>
						)}

						{gameset649 && (
							<GamesetCard
								className={styles.card}
								game={gameset649.game}
								setsNumber={gameset649.amount}
								onSetNumberChange={handleSetsAmountChange}
								onBuy={handleBuyGamesets}
							/>
						)}

						{gameTickets.map(({ game, amount }) => (
							<GameCard
								key={game.game_id}
								className={styles.card}
								game={game}
								ticketsAmount={amount}
								onSetNumberChange={handleTicketsAmountChange}
								onBuy={handleBuyTickets}
							/>
						))}
					</div>
				</>
			)}
		</Layout>
	);
}
