/* eslint-disable no-alert */

const webAppStub = {
	close() {},
	initDataUnsafe: {
		user: {
			language_code: 'ru',
		},
	},
	showPopup(params: PopupParams) {
		const { message } = params;
		window.alert(message);
	},
	showAlert(message: string) {
		window.alert(message);
	},
	showConfirm(message: string, callback?: (ok?: boolean) => void) {
		const isOK = window.confirm(message);
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		callback && callback(isOK);
	},
} as unknown as Telegram['WebApp'];

export const telegram = process.env.NODE_ENV === 'production' ? window.Telegram.WebApp : webAppStub;
