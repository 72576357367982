import { intervalToDuration } from 'date-fns';
import { useTranslation } from 'react-i18next';

const calcTimeLeftToStart = (startDate: Date, endDate: Date | string) => {
	const endDateVal = typeof endDate === 'string' ? new Date(endDate) : endDate;

	return intervalToDuration({
		start: startDate,
		end: endDateVal,
	});
};

export const useFormatTimeLeft = (startDate: Date, endDate: Date) => {
	const { t } = useTranslation();

	if (endDate.getTime() - startDate.getTime() < 1000) {
		return t('drawInfo.sec', { val: 0 });
	}

	const { months, days, hours, minutes, seconds } = calcTimeLeftToStart(startDate, endDate);

	const secondsPart = seconds ? t('drawInfo.sec', { val: seconds }) : '';
	const minutesPart = minutes ? t('drawInfo.minutes', { val: minutes }) : '';
	const hoursPart = hours ? t('drawInfo.hours', { val: hours }) : '';
	const daysPart = days ? t('drawInfo.days', { val: days }) : '';
	const monthPart = months ? t('drawInfo.month', { val: months }) : '';

	let parts: string[] = [];

	if (months) {
		parts = [monthPart, daysPart];
	} else if (days) {
		parts = [daysPart, hoursPart];
	} else if (hours) {
		parts = [hoursPart, minutesPart];
	} else if (minutes) {
		parts = [minutesPart, secondsPart];
	} else {
		parts = [secondsPart];
	}

	return parts.filter(Boolean).join(' ');
};
