export const orderStatusMock = {
	m_order_id: '3001',
	payment_status: 1,
	ticket_count: 2,
	amount: 110000,
	ticket_data: [
		{
			ticket_number: '165A1G1D4T18204',
			game_id: 39,
			bonus: 3,
			ticket_amount: 10000,
			bet_data: [
				{
					bet_id: '165A1G1D4T18204',
					bet_amount: 10000,
					quickpick: 0,
					draw_date: 1981181305,
					draw_no: 668,
					multidraw: 5,
					draw_to_no: 672,
					game_mode: 1,
					multiplier: 1,
					prognosis_bet: [],
				},
			],
		},
		{
			ticket_number: '285A1G1D4T18205',
			game_id: 40,
			bonus: 5,
			ticket_amount: 10000,
			bet_data: [
				{
					bet_id: '285A1G1D4T18205',
					bet_amount: 10000,
					draw_date: '2019-08-12',
					draw_no: 668,
					multidraw: 5,
					draw_to_no: 672,
					quickpick: 0,
					game_mode: 1,
					multiplier: 1,
					prognosis_bet: [],
				},
			],
		},
	],
};

export const providerOrderDataMock = {
	session: 'e1aaed1327493f2eb061c3144affea4bf7d3d435af8f3d4fb0eb1b1d31064883',
	order_id: 143422,
	payment_status: 1,
	ticket_count: 2,
	amount: 110000,
	ticket_data: [
		{
			ticket_number: 555666,
			game_id: 39,
			bonus: 3,
			ticket_amount: 10000,
			bet_data: [
				{
					bet_id: 555666,
					bet_amount: 10000,
					quickpick: 0,
					draw_date: 1981181305,
					draw_no: 668,
					game_mode: 1,
					multiplier: 1,
					prognosis_bet: [],
				},
			],
		},
		{
			ticket_number: 555688,
			game_id: 40,
			bonus: 5,
			ticket_amount: 10000,
			bet_data: [
				{
					bet_id: 555688,
					bet_amount: 10000,
					draw_date: '2019-08-12',
					draw_no: 668,
					quickpick: 0,
					game_mode: 1,
					multiplier: 1,
					prognosis_bet: [],
				},
			],
		},
	],
};

export const orderStatusWithEmptyDrawDateMock = {
	m_order_id: '3001',
	payment_status: 1,
	ticket_count: 2,
	amount: 110000,
	ticket_data: [
		{
			ticket_number: '165A1G1D4T18204',
			game_id: 39,
			bonus: 3,
			ticket_amount: 10000,
			bet_data: [
				{
					bet_id: '165A1G1D4T18204',
					bet_amount: 10000,
					quickpick: 0,
					draw_date: '',
					draw_no: 668,
					multidraw: 5,
					draw_to_no: 672,
					game_mode: 1,
					multiplier: 1,
					prognosis_bet: [],
				},
			],
		},
		{
			ticket_number: '285A1G1D4T18205',
			game_id: 40,
			bonus: 5,
			ticket_amount: 10000,
			bet_data: [
				{
					bet_id: '285A1G1D4T18205',
					bet_amount: 10000,
					draw_date: '',
					draw_no: 668,
					multidraw: 5,
					draw_to_no: 672,
					quickpick: 0,
					game_mode: 1,
					multiplier: 1,
					prognosis_bet: [],
				},
			],
		},
	],
};
