import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { IGameSet, IOrderDetails, IPaymentMethod } from '../../interfaces';

interface OrderState {
	order: IGameSet | null;
	orderType: 'gameSet' | 'gameTickets';
	orderDetails: IOrderDetails | null;
	orderDetailsStatus: 'pending' | 'idle';
	paymentMethods: IPaymentMethod[];
	paymentMethodID: number | null;
}

const initialState: OrderState = {
	order: null,
	orderType: 'gameSet',
	orderDetails: null,
	orderDetailsStatus: 'idle',
	paymentMethods: [],
	paymentMethodID: null,
};

export const orderSlice = createSlice({
	name: 'order',
	initialState,
	reducers: {
		setOrder: (state, action: PayloadAction<IGameSet>) => {
			state.order = action.payload;
		},
		setOrderType: (state, action: PayloadAction<OrderState['orderType']>) => {
			state.orderType = action.payload;
		},
		setOrderDetailsStatus: (state, action: PayloadAction<OrderState['orderDetailsStatus']>) => {
			state.orderDetailsStatus = action.payload;
		},
		setOrderDetails: (state, action: PayloadAction<IOrderDetails>) => {
			state.orderDetails = action.payload;
		},
		setPaymentMethod: (state, action: PayloadAction<OrderState['paymentMethodID']>) => {
			state.paymentMethodID = action.payload;
		},
		setPaymentMethodsList: (state, action: PayloadAction<OrderState['paymentMethods']>) => {
			state.paymentMethods = action.payload;
		},
	},
});

export const {
	setOrder,
	setOrderType,
	setOrderDetails,
	setOrderDetailsStatus,
	setPaymentMethod,
	setPaymentMethodsList,
} = orderSlice.actions;

export const selectOrder = (state: RootState) => state.order.order;
export const selectOrderType = (state: RootState) => state.order.orderType;
export const selectOrderDetails = (state: RootState) => state.order.orderDetails;
export const selectOrderDetailsStatus = (state: RootState) => state.order.orderDetailsStatus;
export const selectPaymentMethod = (state: RootState) => state.order.paymentMethodID;
export const selectPaymentMethodsList = (state: RootState) => state.order.paymentMethods;

export default orderSlice.reducer;
