import { IGameSet, ILotteryAPIOrderStatus, IOrderDetails } from '../../interfaces';
import { moneyFromMDE } from '../../utils/dataUtils';

const unixTimestampToISODate = (timestamp: number) => new Date(timestamp * 1000).toISOString();

export const composePurchaseDetailsFromFailedOrder = (
	data: ILotteryAPIOrderStatus,
	order: IGameSet,
	status: IOrderDetails['status'],
): IOrderDetails => {
	const { amount, ticket_count, error } = data;
	const orderID = data.m_order_id;
	const purchaseDate = new Date().toISOString();

	const {
		game: { draw_date, draw_number, game_id, name: gameName },
	} = order;

	const totalPrice = moneyFromMDE(amount);

	return {
		gameID: game_id,
		gameName,
		orderID,
		purchaseDate,
		ticketsAmount: ticket_count,
		totalPrice,
		drawDate: draw_date,
		drawNumber: draw_number,
		error,
		status,
	};
};

export const composePurchaseDetailsFromLotteryModuleOrder = (
	data: ILotteryAPIOrderStatus,
	order: IGameSet,
): IOrderDetails => {
	const { amount, m_order_id, ticket_count, ticket_data, error } = data;

	const {
		game: { game_id, name: gameName },
	} = order;

	if (error) {
		return composePurchaseDetailsFromFailedOrder(data, order, 'error');
	}

	const ticket = ticket_data && ticket_data[0];
	const bet = ticket?.bet_data[0];
	const drawDateRaw = bet?.draw_date;

	const drawDate = drawDateRaw
		? { drawDate: typeof drawDateRaw === 'number' ? unixTimestampToISODate(drawDateRaw) : drawDateRaw }
		: {};

	const drawNumber = bet?.draw_no;

	return {
		gameID: game_id,
		gameName,
		...drawDate,
		drawNumber,
		orderID: m_order_id,
		purchaseDate: new Date().toISOString(),
		ticketsAmount: ticket_count,
		totalPrice: moneyFromMDE(amount),
		status: 'success',
	};
};
