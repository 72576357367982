import { AxiosResponse } from 'axios';
import { IGame } from '../interfaces';
import { platformInstance } from './instance';
import { correctZeroOffsetDate } from '../utils/correctZeroOffsetDate';

export const platformGameIDs = [
	'bet_sz_420',
	'bet_sz_820',
	'bet_sz_649',
	'bet_sz_keno',
	'bet_sz_keno2',
	'bet_sz_536',
	'bet_sz_777',
	'bet_sz_telebingo',
];

export const platformGameIDToAppGameIDMap: Record<string, string> = {
	bet_sz_420: 'bet_sz_420',
	bet_sz_820: 'bet_sz_820',
	bet_sz_649: 'bet_cl_649',
	bet_sz_keno: 'bet_cl_keno',
	bet_sz_keno2: 'bet_cl_keno2',
	bet_sz_536: 'bet_cl_536',
	bet_sz_777: 'bet_cl_777',
	bet_sz_telebingo: 'bet_cl_telebingo',
};

const filterGames = (game: IGame) => platformGameIDs.includes(game.game_id);

/**
 * Application API
 * 7.3 Общий метод. Список игр мобильного приложения. Метод /app/games
 */
export const getGames = async (): Promise<AxiosResponse<IGame[]>> => {
	const result = await platformInstance.post<IGame[]>('/app/games');
	const payload = result.data;

	const filteredGames = payload.filter(filterGames);

	const games = filteredGames.map((game) => {
		const drawDate = new Date(game.draw_date);

		return {
			...game,
			// game_id изменился. Некоторые стили и текстовые ресурсы
			// привязаны к конкретным game_id. Чтобы не переписывать половину приложения,
			// приведем все game_id внутри приложения к тому, что было изначально на деве
			game_id: platformGameIDToAppGameIDMap[game.game_id],
			draw_date: correctZeroOffsetDate(drawDate).toISOString(),
		};
	});

	return {
		...result,
		data: games,
	};
};
