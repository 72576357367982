import { IGameSet } from '../interfaces';

const LM_ORDER_ID = 'm_order_id';
const KEY_GAME_ID = 'gameID';
const KEY_GAME_NAME = 'gameName';
const KEY_REPORT_ID = 'reportID';
const KEY_ORDER = 'order';

interface IOrderBasic {
	gameID: string;
	gameName: string;
}

export const saveOrderBasic = ({ gameID, gameName }: IOrderBasic) => {
	window.sessionStorage.setItem(KEY_GAME_ID, gameID);
	window.sessionStorage.setItem(KEY_GAME_NAME, gameName);
};

export const loadOrderBasic = (): IOrderBasic => {
	const gameID = window.sessionStorage.getItem(KEY_GAME_ID);
	const gameName = window.sessionStorage.getItem(KEY_GAME_NAME);

	if (!gameID) {
		console.error('Cannot load gameID');
	}

	if (!gameName) {
		console.error('Cannot load gameName');
	}

	return {
		gameID: gameID ?? '',
		gameName: gameName ?? '',
	};
};

export const saveOrder = (order: IGameSet) => {
	window.sessionStorage.setItem(KEY_ORDER, JSON.stringify(order));
};

export const loadOrder = (): IGameSet | null => {
	const data = window.sessionStorage.getItem(KEY_ORDER);
	return data && JSON.parse(data);
};

export const saveLotteryModuleOrderID = (orderID: string) => {
	window.sessionStorage.setItem(LM_ORDER_ID, orderID);
};

export const loadLotteryModuleOrderID = () => {
	return window.sessionStorage.getItem(LM_ORDER_ID);
};

export const saveSubmittedReportID = (orderID: string) => {
	window.sessionStorage.setItem(KEY_REPORT_ID, orderID);
};

export const loadSubmittedReportID = () => {
	return window.sessionStorage.getItem(KEY_REPORT_ID);
};

export const clearStorage = () => {
	// !!! Нельзя использовать window.sessionStorage.clear(), потому что Телеграм пишет в sessionStorage служебную информацию
	window.sessionStorage.removeItem(LM_ORDER_ID);
	window.sessionStorage.removeItem(KEY_GAME_ID);
	window.sessionStorage.removeItem(KEY_GAME_NAME);
	window.sessionStorage.removeItem(KEY_REPORT_ID);
	window.sessionStorage.removeItem(KEY_ORDER);
};
