import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { AxiosResponse } from 'axios';

import { setOrderDetails, setOrderDetailsStatus } from './orderSlice';
import { telegram } from '../../utils/telegram';
import { getCompleteOrderDetails } from './orderStatusUtils';
import { IGameSet, ILotteryAPIOrderStatus } from '../../interfaces';

type Fetcher = (orderID: string) => Promise<AxiosResponse<ILotteryAPIOrderStatus>>;

interface IArg {
	orderID: string;
	order: IGameSet;
	fetcher: Fetcher;
	fetchInterval?: number;
}

export const getOrderStatusThunk = createAsyncThunk<void, IArg>(
	'order/getOrderStatus',
	async ({ orderID, order, fetcher, fetchInterval }, { dispatch, signal }) => {
		dispatch(setOrderDetailsStatus('pending'));

		try {
			if (orderID) {
				const { abort, result } = getCompleteOrderDetails(orderID, order, fetcher, fetchInterval);

				signal.addEventListener('abort', () => {
					abort();
				});

				const orderDetails = await result;
				dispatch(setOrderDetails(orderDetails));
			} else {
				console.error('Missing required ID');
			}
		} catch (error) {
			console.error(error);

			setTimeout(() => {
				telegram.showAlert(i18next.t('common.error'));
			}, 0);
		} finally {
			dispatch(setOrderDetailsStatus('idle'));
		}
	},
);
