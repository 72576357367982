import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { getPaymentMethods } from '../api/getPaymentMethods';
import { setPaymentMethodsList } from './order';
import { setLoading } from './appSlice';
import { telegram } from '../utils/telegram';

export const getPaymentMethodThunk = createAsyncThunk('order/getPaymentMethods', async (_, { dispatch }) => {
	dispatch(setLoading(true));

	try {
		const { data } = await getPaymentMethods();
		const { ButtonList: paymentMethods } = data;
		dispatch(setPaymentMethodsList(paymentMethods));
	} catch (error) {
		telegram.showAlert(i18next.t('common.networkError'));
		console.error(error);
	} finally {
		dispatch(setLoading(false));
	}
});
