import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from './PaymentStatus.module.scss';
import { useAppSelector } from '../../store';
import { selectLanguage } from '../../store/appSlice';

interface IProps {
	className?: string;
	variant: 'pending' | 'success' | 'error';
}

export function PaymentStatus({ className, variant }: IProps) {
	const { t } = useTranslation();

	const lang = useAppSelector(selectLanguage);
	const siteHref = lang === 'kz' ? 'https://sz.kz/?SetLanguage=ru-RU' : 'https://sz.kz/?SetLanguage=kz-KZ';

	const siteLink = (
		// eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
		<a href={siteHref} target="_blank" rel="noreferrer" />
	);

	const titleContainerStyles = cn(styles.titleContainer, {
		[styles.pending]: variant === 'pending',
		[styles.success]: variant === 'success',
		[styles.error]: variant === 'error',
	});

	return (
		<div className={cn(styles.root, className)}>
			<div className={titleContainerStyles}>
				<p className={styles.title}>{t(`paymentStatus.${variant}.title`)}</p>
				<p className={styles.description}>
					<Trans i18nKey={`paymentStatus.${variant}.description`} components={{ Link: siteLink }} />
				</p>
			</div>
		</div>
	);
}
