import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { botInstance } from './instance';
import { IOrderDetails } from '../interfaces';

interface IAPIOrderDetailsReport {
	user_id: string;
	edititon: number;
	logo_id: string;
	bought_datetime: string; // 12.12.2022 18:12
	lotery_date: string; // 15.12.2023 18:12
	order_number: number;
	tickets_number: number;
	amount: number;
}

export interface IOrderDetailsReport extends IOrderDetails {
	userID: number;
}

const convertToServer = (order: IOrderDetailsReport): IAPIOrderDetailsReport => {
	const {
		userID,
		gameID,
		orderID,
		purchaseDate: purchaseDateString,
		ticketsAmount,
		totalPrice,
		drawDate: drawDateString,
		drawNumber,
	} = order;

	const purchaseDate = new Date(purchaseDateString);
	const drawDate = drawDateString ? new Date(drawDateString) : null;
	const formattedDrawDate = drawDate ? format(drawDate, 'd.MM.yyyy HH:mm') : '';

	return {
		user_id: String(userID),
		logo_id: gameID,
		amount: totalPrice,
		bought_datetime: format(purchaseDate, 'd.MM.yyyy HH:mm'),
		edititon: drawNumber ?? 0,
		lotery_date: formattedDrawDate,
		order_number: Number(orderID),
		tickets_number: ticketsAmount,
	};
};

/**
 * Proxy API
 */
export const submitOrderDetailsReport = async (order: IOrderDetailsReport): Promise<AxiosResponse<void>> => {
	window.sessionStorage.removeItem('reportID');
	return botInstance.post('/front_callback', convertToServer(order));
};
