import { AxiosResponse } from 'axios';
import { IWithAPIError } from '../interfaces/IAPIError';
import { microserviceInstance } from './instance';

const isFakeAPI = process.env.REACT_APP_FAKE_API === 'true';

interface ITicketInfoItem {
	product_name?: string; // можно не посылать
	product_amount: 1; // всегда
	product_sum: number;
	app_id: number;
	game_id: number; // см. https://docs.google.com/document/d/15R-l09hTb-2Rwo3cvxi0vk_ckIAI52ElzlOvoRYGLCk/edit?usp=sharing
	draw_no: number;
	multidraw?: number;
	draw_to_no?: number;
	game_mode?: number;
	multiplier?: number;
	prognosis_bet?: number[] | number[][];
}

export interface ILotteryAPITicketsOrder {
	player_phone: string;
	payment_info: {
		payment_method_id: number;
		token?: string; // Только для LotoWallet
		payment_type?: number; // Только для LotoWallet
		success_url: string;
		failure_url: string;
		amount: number; // Сумма платежа в МДЕ
		description?: string;
	};
	res_data1?: string;
	ticket_info: ITicketInfoItem[];
}

interface IOrderResponse extends IWithAPIError {
	m_order_id: string;
	res_data1: string;
	payment_url?: string;
}

/**
 * Лотерейный модуль API. Для оплаты 4/20, 8/20.
 * 4.7. Запустить процедуру оплаты и регистрации билета. Метод /gporder
 * https://docs.google.com/document/d/16-K3X63xGEwdqJlMtw6jC9l3TrGmAzKU/edit#heading=h.lnxbz9
 */
export const submitOrderLotteryAPI = async (order: ILotteryAPITicketsOrder): Promise<AxiosResponse<IOrderResponse>> => {
	if (!isFakeAPI) {
		return microserviceInstance.post('/gporder', order);
	}

	return new Promise((resolve) => {
		const originURL = window.location.origin;

		setTimeout(() => {
			resolve({
				data: {
					m_order_id: '3001',
					res_data1: 'string',
					payment_url: `${originURL}/purchase`,
				},
			} as unknown as AxiosResponse<IOrderResponse>);
		}, 1000);
	});
};
