import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface RegistrationState {
	phone: string | null;
	submitPhoneStatus: 'idle' | 'pending';
	smsCode: string | null;
	verificationStatus: 'idle' | 'pending';
}

const initialState: RegistrationState = {
	phone: null,
	submitPhoneStatus: 'idle',
	smsCode: null,
	verificationStatus: 'idle',
};

export const registrationSlice = createSlice({
	name: 'registration',
	initialState,
	reducers: {
		setPhone: (state, action: PayloadAction<string>) => {
			state.phone = action.payload;
		},
		setSubmitPhoneStatus: (state, action: PayloadAction<RegistrationState['submitPhoneStatus']>) => {
			state.submitPhoneStatus = action.payload;
		},
		setCode: (state, action: PayloadAction<RegistrationState['smsCode']>) => {
			state.smsCode = action.payload;
		},
		setVerificationStatus: (state, action: PayloadAction<RegistrationState['verificationStatus']>) => {
			state.verificationStatus = action.payload;
		},
		resetRegistrationState: () => initialState,
	},
});

export const { setPhone, setCode, setSubmitPhoneStatus, setVerificationStatus, resetRegistrationState } =
	registrationSlice.actions;

export const selectPhone = (state: RootState) => state.registration.phone;
export const selectVerificationStatus = (state: RootState) => state.registration.verificationStatus;
export const selectVerificationCode = (state: RootState) => state.registration.smsCode;
export const selectSubmitPhoneStatus = (state: RootState) => state.registration.submitPhoneStatus;

export default registrationSlice.reducer;
