import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * @param timeout время в секундах
 * @returns {*} resetCountdown — сброс таймера в предустановленное ранее состояние; setCountdown — установка таймера; countdownValue — текущее значение таймера
 */
export const useCountdown = (timeout: number) => {
	const [cachedTimeout, setCachedTimeout] = useState(timeout);
	const [timeoutValue, setTimeoutValue] = useState(timeout);
	const timerIDRef = useRef<number | null>(null);

	useEffect(() => {
		const timerID = timerIDRef.current;

		return () => {
			if (timerID) {
				window.clearTimeout(timerID);
			}
		};
	}, []);

	useEffect(() => {
		if (timeoutValue > 0) {
			timerIDRef.current = window.setTimeout(() => {
				setTimeoutValue(timeoutValue - 1);
			}, 1000);
		}

		return () => {
			if (timerIDRef.current) {
				window.clearTimeout(timerIDRef.current);
			}
		};
	}, [timeoutValue]);

	const resetCountdown = useCallback(() => {
		setTimeoutValue(cachedTimeout);
	}, [cachedTimeout]);

	const setCountdown = useCallback((val: number) => {
		setCachedTimeout(val);
		setTimeoutValue(val);
	}, []);

	return {
		resetCountdown,
		setCountdown,
		countdownValue: timeoutValue,
	};
};
