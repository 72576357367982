import { useEffect, useRef } from 'react';
import { usePrevious } from 'react-use';
import cn from 'classnames';

import styles from './CodeInput.module.scss';
import { CharInput, ICharInputProps } from './CharInput';

import { noop } from '../../../../../../utils/noop';

interface IProps {
	className?: string;
	length?: number;
	value?: string[];
	error?: boolean;
	onChange?: (value: string[]) => void;
	onComplete?: () => void;
}

const VALID_CHARS = /[0-9]/;

export function CodeInput({
	className,
	length = 4,
	value = [],
	error = false,
	onChange = noop,
	onComplete = noop,
}: IProps) {
	const rootRef = useRef<HTMLDivElement>(null);
	const prevValue = usePrevious(value);

	useEffect(() => {
		if (value !== prevValue && value.every(Boolean)) {
			onComplete();
		}
	}, [length, onComplete, prevValue, value, value.length]);

	const handleInputChange: ICharInputProps['onValueChange'] = (char, index) => {
		const newValue = value.slice();
		newValue[index] = char;
		onChange(newValue);

		// focus next input
		const root = rootRef.current;

		if (!root) return;

		const inputs = Array.from(root.children) as HTMLInputElement[];
		const nextInput = inputs[index + 1];

		if (nextInput && char) {
			nextInput.focus();
		}
	};

	const handleBackspaceClick = (index: number) => {
		const newValue = value.slice();
		newValue[index] = '';
		onChange(newValue);

		// focus previuos input
		const root = rootRef.current;

		if (!root) return;

		const inputs = Array.from(root.children) as HTMLInputElement[];
		const prevInput = inputs[index - 1];

		if (prevInput) {
			prevInput.focus();
		}
	};

	return (
		<div ref={rootRef} className={cn(className, styles)}>
			{Array(length)
				.fill(null)
				.map((_, i) => {
					return (
						<CharInput
							key={i}
							className={styles.input}
							index={i}
							inputMode="numeric"
							inputPattern={VALID_CHARS}
							value={value[i] ?? ''}
							error={error}
							onValueChange={handleInputChange}
							onBackspaceClick={handleBackspaceClick}
						/>
					);
				})}
		</div>
	);
}
