import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { isAxiosError } from 'axios';

import { IGameSet } from '../../interfaces';
import {
	GAMESET_TICKETS_AMOUNT_MAP,
	gameModeMap,
	TICKET_APP_ID_MAP_DEV,
	TICKET_APP_ID_MAP_PROD,
	renameGames,
	renameGamesDescription,
} from '../../constants';
import { AppDispatch, RootState } from '../store';
import { setLoading } from '../appSlice';
import { Routes } from '../../enums/routes.enum';
import { ILotteryAPITicketsOrder, submitOrderLotteryAPI } from '../../api/submitOrderLotteryAPI';
import { saveLotteryModuleOrderID, saveOrder, saveOrderBasic } from '../../utils/orderStorage';
import { telegram } from '../../utils/telegram';
import { generateLotteryAPIBets, getOrderAPIGameID } from './purchaseTicketsThunkUtils';

interface ISubmitOrder {
	gameset: IGameSet;
	phone: string;
	paymentMethodID: number;
}

export const purchaseTicketsThunk = createAsyncThunk<void, ISubmitOrder, { dispatch: AppDispatch; state: RootState }>(
	'order/purchaseTickets',
	async (order, { dispatch, getState }) => {
		const { gameset, phone, paymentMethodID } = order;
		const orderType = getState().order.orderType;
		const { game, amount } = gameset;
		const { draw_number, game_id, name: gameName } = game;
		const orderAPIGameID = getOrderAPIGameID(game_id);

		saveOrderBasic({
			gameID: game_id,
			gameName,
		});

		saveOrder(order.gameset);
		dispatch(setLoading(true));

		const ticketsInSet = orderType === 'gameSet' ? GAMESET_TICKETS_AMOUNT_MAP[game_id] ?? 0 : 1;
		const ticketsAmount = amount * ticketsInSet;

		const appID =
			process.env.REACT_APP_MODE === 'development'
				? TICKET_APP_ID_MAP_DEV[game_id]
				: TICKET_APP_ID_MAP_PROD[game_id];

		try {
			const tickets: ILotteryAPITicketsOrder['ticket_info'] = Array(ticketsAmount)
				.fill(null)
				.map(() => {
					let gameRename = gameName;
					if (game_id in renameGames) {
						gameRename = renameGames[game_id];
					}

					return {
						game_id: orderAPIGameID,
						product_amount: 1,
						app_id: appID,
						draw_no: draw_number,
						product_sum: game.price,
						product_name: `${gameRename}`,
						multidraw: 0,
						draw_to_no: draw_number,
						game_mode: gameModeMap[game_id],
						multiplier: 1,
						prognosis_bet: generateLotteryAPIBets(game_id),
					};
				});

			const orderPrice = tickets.reduce((acc, curr) => acc + curr.product_sum, 0);
			const purchaseStatusPageURL = window.location.origin + Routes.Purchase;

			let gameRenameInDescription = gameName;
			if (game_id in renameGamesDescription) {
				gameRenameInDescription = renameGamesDescription[game_id];
			}
			const orderData: ILotteryAPITicketsOrder = {
				player_phone: phone,
				payment_info: {
					payment_method_id: paymentMethodID,
					success_url: purchaseStatusPageURL,
					failure_url: purchaseStatusPageURL,
					amount: orderPrice,
					description: `Оплата ${ticketsAmount} билета(ов) ${gameRenameInDescription}`,
				},
				ticket_info: tickets,
			};

			const { data } = await submitOrderLotteryAPI(orderData);
			const { m_order_id, payment_url, error } = data;

			saveLotteryModuleOrderID(m_order_id);

			if (error) {
				telegram.showPopup({
					title: i18next.t('common.error'),
					message: error.message,
				});

				console.error(error);
				return;
			}

			if (payment_url) {
				window.location.href = payment_url;
			} else {
				console.error('payment_url is missed');
				telegram.showAlert(i18next.t('common.error'));
			}
		} catch (error) {
			console.error(error);

			if (!isAxiosError(error)) {
				telegram.showAlert(i18next.t('common.networkError'));
				return;
			}

			if (error.response?.status === 401) {
				telegram.showAlert('Unauthorized');
			} else {
				telegram.showAlert(i18next.t('common.error'));
			}
		} finally {
			dispatch(setLoading(false));
		}
	},
);
