import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './PurchaseStatusPage.module.scss';
import { Button } from '../../components/Button';
import { Layout } from '../../layouts';
import { Header } from '../../widgets/Header';
import { PaymentStatus } from '../../widgets/PaymentStatus';
import { IPurchaseDetailsProps, PurchaseDetails } from './PurchaseDetails';

import { useAppDispatch, useAppSelector } from '../../store';
import { getOrderStatusThunk, selectOrderDetails, submitOrderDetailsReportThunk } from '../../store/order';
import { clearStorage, loadLotteryModuleOrderID, loadOrder, loadSubmittedReportID } from '../../utils/orderStorage';
import { Routes } from '../../enums/routes.enum';
import { useReloadApp } from '../../utils/useReloadApp';
import { telegram } from '../../utils/telegram';
import { getLotteryAPIOrderStatus } from '@/api/getLotteryAPIOrderStatus';

type TOrderDetailsStatus = 'pending' | 'success' | 'error';

export function PurchaseStatusPage() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const orderDetails = useAppSelector(selectOrderDetails);
	const lotteryModuleOrderID = loadLotteryModuleOrderID();
	const isPurchaseReportSubmitted = Boolean(loadSubmittedReportID());
	const isNeedToReloadApp = !lotteryModuleOrderID;

	const purchaseDetails: IPurchaseDetailsProps['order'] | null = orderDetails && {
		gameID: orderDetails.gameID,
		gameName: orderDetails.gameName,
		orderID: orderDetails.orderID,
		purchaseDate: new Date(orderDetails.purchaseDate),
		drawDate: orderDetails.drawDate ? new Date(orderDetails.drawDate) : undefined,
		drawNumber: orderDetails.drawNumber,
		ticketsAmount: orderDetails.ticketsAmount,
		totalPrice: orderDetails.totalPrice,
	};

	// при нажатии «перезагрузить страницу» в меню Телеграма грузим приложение заново
	// (в случае невозможности восстановить состояние этой страницы)
	useReloadApp(() => isNeedToReloadApp);

	useEffect(() => {
		if (isNeedToReloadApp) return;

		let orderThunkPromise: Promise<unknown> & {
			abort: (reason?: string | undefined) => void;
		};

		const order = loadOrder();

		if (!order) {
			throw new Error('Error loading order from storage');
		}

		if (lotteryModuleOrderID && order) {
			orderThunkPromise = dispatch(
				getOrderStatusThunk({ orderID: lotteryModuleOrderID, order, fetcher: getLotteryAPIOrderStatus }),
			);
		} else {
			console.error('Missing order or order ID');
		}

		// eslint-disable-next-line consistent-return
		return () => {
			orderThunkPromise.abort();
		};
	}, [dispatch, isNeedToReloadApp, lotteryModuleOrderID]);

	const handleBuyMoreClick = () => {
		clearStorage();
		window.location.href = `${window.location.origin}${Routes.Home}`;
	};

	let title = '';
	let orderDetailsStatus: TOrderDetailsStatus = 'pending';

	if (orderDetails) {
		title = orderDetails.status === 'success' ? t('purchasePage.titleSuccess') : t('purchasePage.titleError');
		orderDetailsStatus = orderDetails.status === 'success' ? 'success' : 'error';
	}

	const userID = telegram.initDataUnsafe.user?.id;

	useEffect(() => {
		if (!orderDetails || orderDetailsStatus !== 'success' || isPurchaseReportSubmitted) return;

		if (userID) {
			dispatch(
				submitOrderDetailsReportThunk({
					...orderDetails,
					userID,
				}),
			);
			clearStorage();
			orderDetailsStatus = 'pending';
		}
	}, [dispatch, isPurchaseReportSubmitted, orderDetails, orderDetailsStatus]);

	return (
		<Layout className={styles.layout}>
			<Header title={title} closable />
			<PaymentStatus className={styles.paymentStatus} variant={orderDetailsStatus} />

			{purchaseDetails && (
				<>
					<PurchaseDetails className={styles.purchaseDetails} order={purchaseDetails} />

					{orderDetailsStatus !== 'pending' && (
						<Button className={styles.buyMore} fullwidth onClick={handleBuyMoreClick}>
							{t('purchasePage.buyMore')}
						</Button>
					)}
				</>
			)}
		</Layout>
	);
}
