import { Component, ReactNode } from 'react';

interface IState {
	hasError: boolean;
	error: Error;
	componentStack: any;
}

interface IProps {
	children: ReactNode;
}

export class ErrorBoundary extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = { hasError: false } as IState;
	}

	static getDerivedStateFromError(error: Error) {
		return { hasError: true, error };
	}

	componentDidCatch(error: Error, errorInfo: any) {
		this.setState({ componentStack: errorInfo.componentStack });
	}

	render() {
		const { hasError, error, componentStack } = this.state;

		if (hasError) {
			return (
				<>
					<h1>Ошибка</h1>
					<p>{error.message}</p>
					<h2>Stacktrace</h2>
					<p>{(error as Error).stack}</p>
					<h2>Component stack</h2>
					<p>{componentStack}</p>
				</>
			);
		}

		return this.props.children;
	}
}
