import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import i18next from 'i18next';
import { telegram } from '../utils/telegram';
import { getLanguage } from '../api/getLanguage';
import { setAPIInstancesLang } from '../api/instance';
import { DEFAUL_LANG, TELEGRAM_LANG_KAZAKH } from '../constants';
import { setAppReady, setLanguage } from './appSlice';

export const initAppThunk = createAsyncThunk('app/initApp', async (_, { dispatch }) => {
	const userID = telegram.initDataUnsafe.user?.id;
	const telegramLang = telegram.initDataUnsafe.user?.language_code;

	try {
		if (!userID) {
			console.error('Missing User ID');
			return;
		}

		const {
			data: { lang_code },
		} = await getLanguage(String(userID));

		const lang = lang_code === TELEGRAM_LANG_KAZAKH ? 'kz' : DEFAUL_LANG;
		dispatch(setLanguage(lang));
		i18next.changeLanguage(lang);
		setAPIInstancesLang(lang);
	} catch (error) {
		const errMessage =
			isAxiosError(error) && error.code === 'ERR_NETWORK'
				? i18next.t('common.networkError', { lng: telegramLang })
				: i18next.t('common.error', { lng: telegramLang });

		telegram.showAlert(errMessage);
		console.error(error);
	} finally {
		dispatch(setAppReady(true));
	}
});
