import { ComponentPropsWithoutRef, FC, useId } from 'react';
import cn from 'classnames';

import styles from './Checkbox.module.scss';

interface IProps extends Omit<ComponentPropsWithoutRef<'input'>, 'type'> {
	label?: React.ReactNode;
}

export const Checkbox: FC<IProps> = ({ className, id, label, checked, ...props }) => {
	const reactid = useId();
	const inputID = id || reactid;

	return (
		<label htmlFor={inputID} className={cn(className, styles.root, checked && styles.checked)}>
			<input
				{...props}
				type="checkbox"
				id={inputID}
				checked={checked}
				className={cn('visually-hidden', styles.input)}
			/>

			{label && <span className={styles.label}>{label}</span>}
		</label>
	);
};
