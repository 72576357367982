import cn from 'classnames';

import styles from './Header.module.scss';
import { ReactComponent as CloseIcon } from '../../static/img/icons/close.svg';
import { ReactComponent as BackIcon } from '../../static/img/icons/back.svg';

import { noop } from '../../utils/noop';
import { telegram } from '../../utils/telegram';
import { clearStorage } from '../../utils/orderStorage';

interface IProps {
	className?: string;
	title?: string;
	closable?: boolean;
	back?: boolean;
	onBackClick?: () => void;
	onBeforeClose?: () => void;
}

export function Header({ className, title, closable, back, onBackClick, onBeforeClose = noop }: IProps) {
	const handleCloseClick = () => {
		onBeforeClose();
		clearStorage();
		telegram.close();
	};

	return (
		<header className={cn(styles.root, className)}>
			{back && (
				<button type="button" className={styles.back} onClick={onBackClick}>
					<BackIcon />
				</button>
			)}

			{title && <h1 className={styles.title}>{title}</h1>}

			{closable && (
				<button type="button" className={styles.close} onClick={handleCloseClick}>
					<CloseIcon />
				</button>
			)}
		</header>
	);
}
