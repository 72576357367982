export const DEFAUL_LANG = 'ru';
export const TELEGRAM_LANG_KAZAKH = 'kk';

export const appGameIDs = [
	'bet_sz_420',
	'bet_sz_820',
	'bet_cl_649',
	'bet_cl_keno',
	'bet_cl_keno2',
	'bet_cl_536',
	'bet_cl_777',
	'bet_cl_telebingo',
];

export const GAMESET_TICKETS_AMOUNT_MAP: Record<(typeof appGameIDs)[number], number> = {
	bet_sz_420: 10,
	bet_sz_820: 10,
	bet_cl_649: 6,
	bet_cl_keno: 10,
	bet_cl_keno2: 10,
	bet_cl_536: 6,
	bet_cl_777: 6,
	bet_cl_telebingo: 5,
};

export const lotteryGameIDMap: Record<(typeof appGameIDs)[number], number> = {
	bet_sz_420: 2,
	bet_sz_820: 1,
	bet_cl_649: 101,
	bet_cl_keno: 102,
	bet_cl_keno2: 105,
	bet_cl_536: 106,
	bet_cl_777: 103,
	bet_cl_telebingo: 104,
};

export const gameModeMap: Record<(typeof appGameIDs)[number], number> = {
	bet_sz_420: 31,
	bet_sz_820: 1,
	bet_cl_649: 1,
	bet_cl_keno: 10,
	bet_cl_keno2: 10,
	bet_cl_536: 1,
	bet_cl_777: 6,
	bet_cl_telebingo: 100,
};

export const TICKET_APP_ID_MAP_DEV: Record<(typeof appGameIDs)[number], number> = {
	bet_sz_420: 3,
	bet_sz_820: 3,
	bet_cl_649: 1,
	bet_cl_keno: 1,
	bet_cl_keno2: 1,
	bet_cl_536: 1,
	bet_cl_777: 1,
	bet_cl_telebingo: 1,
};

export const TICKET_APP_ID_MAP_PROD: Record<(typeof appGameIDs)[number], number> = {
	bet_sz_420: 1,
	bet_sz_820: 1,
	bet_cl_649: 3,
	bet_cl_keno: 3,
	bet_cl_keno2: 3,
	bet_cl_536: 3,
	bet_cl_777: 3,
	bet_cl_telebingo: 3,
};

export const renameGames: Record<(typeof appGameIDs)[number], string> = {
	bet_cl_649: 'Loto 6/49 / Loto 6/49',
	bet_cl_777: '777 / 777',
	bet_cl_keno: 'KENO / KENO',
	bet_cl_telebingo: 'TeleBingo / TeleBingo',
	bet_cl_536: 'Loto 5/36 / Loto 5/36',
	bet_cl_keno2: 'KENO2 / KENO2',
	bet_sz_420: 'Loto 4/20 / Loto 4/20',
	bet_sz_820: 'Билет 8/20',
};

export const renameGamesDescription: Record<(typeof appGameIDs)[number], string> = {
	bet_sz_420: 'Loto 4/20',
	bet_sz_820: 'Loto 8/20',
	bet_cl_telebingo: 'TeleBingo',
	bet_cl_536: '5/36',
	bet_cl_649: '6/49',
};
