import { CSSProperties, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './PaymentPage.module.scss';
import { GamePict } from '../../components/GamePict';
import { Layout } from '../../layouts';
import { Header } from '../../widgets/Header';
import { DrawInfo } from '../../components/DrawInfo';
import { Button } from '../../components/Button';

import { Routes } from '../../enums/routes.enum';
import { GAMESET_TICKETS_AMOUNT_MAP } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../store';
import {
	selectOrder,
	selectOrderType,
	selectPaymentMethodsList,
	setPaymentMethod,
	selectPaymentMethod,
} from '../../store/order';
import { getPaymentMethodThunk } from '../../store/getPaymentMethodThunk';
import { selectLanguage } from '../../store/appSlice';
import { moneyFromMDE } from '../../utils/dataUtils';
import { useReloadApp } from '../../utils/useReloadApp';
import { clearStorage, loadSubmittedReportID } from '@/utils/orderStorage';

const priceFormatter = Intl.NumberFormat('ru', {
	style: 'decimal',
	minimumFractionDigits: 0,
});

const drawInfoStyle: CSSProperties = {
	fontSize: '16px',
	fontWeight: 400,
	color: '#8693AB',
	lineHeight: '22px',
};

export function PaymentPage() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const isPurchaseReportSubmitted = Boolean(loadSubmittedReportID());
	const order = useAppSelector(selectOrder);
	const orderType = useAppSelector(selectOrderType);
	const paymentMethods = useAppSelector(selectPaymentMethodsList);
	const currentPaymentMethod = useAppSelector(selectPaymentMethod);
	const lang = useAppSelector(selectLanguage);
	const isNeedToReloadApp = !order;

	// при нажатии «перезагрузить страницу» в меню Телеграма грузим приложение заново
	useReloadApp(() => isNeedToReloadApp);

	// Очистка reportID в случае закрытия окна и открытия вновь после успешной покупки
	useEffect(() => {
		if (isPurchaseReportSubmitted) {
			clearStorage();
		}
	}, [isPurchaseReportSubmitted]);

	useEffect(() => {
		if (!isNeedToReloadApp && !currentPaymentMethod) {
			dispatch(getPaymentMethodThunk());
		}
	}, [dispatch, isNeedToReloadApp, currentPaymentMethod]);

	const handlePaymentMethodClick = (paymentMethodID: number) => {
		dispatch(setPaymentMethod(paymentMethodID));
		navigate(Routes.Registration);
	};

	if (!order) {
		return null;
	}

	const { game, amount: orderItemsAmount } = order;
	const { game_id, draw_date, draw_number, name: gameName, price } = game;
	const drawDate = typeof draw_date === 'string' ? new Date(draw_date) : draw_date;
	const ticketsAmountInGameset = GAMESET_TICKETS_AMOUNT_MAP[game_id] ?? 0;
	const ticketsAmount = orderType === 'gameSet' ? orderItemsAmount * ticketsAmountInGameset : orderItemsAmount;

	return (
		<Layout>
			<Header title={t('paymentPage.title')} />

			<div className={styles.gameCard}>
				<GamePict className={styles.pict} id={game_id} name={gameName} />

				<div className={styles.info}>
					<p className={styles.gameName}>
						{orderType === 'gameSet'
							? t('paymentPage.card.gamesetName', {
									name: gameName,
									interpolation: { escapeValue: false },
							  })
							: t('paymentPage.card.ticketsName', {
									name: gameName,
									interpolation: { escapeValue: false },
							  })}
					</p>

					<DrawInfo
						className={styles.drawInfo}
						date={drawDate}
						drawNumber={draw_number}
						style={drawInfoStyle}
					/>

					<p className={styles.purchaseDetails}>
						{t('paymentPage.card.ticketsNumber', { count: ticketsAmount })}{' '}
						<span className={styles.dash}>—</span>{' '}
						<span className={styles.purchaseDetailsPrice}>
							{priceFormatter.format(moneyFromMDE(ticketsAmount * price))} ₸
						</span>
					</p>
				</div>
			</div>

			<div className={styles.paymentMethodsButtons}>
				{paymentMethods.map((paymentMethod) => {
					const { payment_method_id, image_url, display_name_kz, display_name_ru } = paymentMethod;

					return (
						<Button
							key={payment_method_id}
							className={styles.btn}
							fullwidth
							onClick={() => handlePaymentMethodClick(payment_method_id)}
						>
							<span className={styles.btnIcon} style={{ backgroundImage: `url(${image_url})` }} />
							{lang === 'kz' ? display_name_kz : display_name_ru}
						</Button>
					);
				})}
			</div>
		</Layout>
	);
}
