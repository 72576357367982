import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { IGame } from '../interfaces';
import { IGameSet } from '../interfaces/IGameSet';

interface CatalogState {
	gamesets: IGameSet[]; // лотонаборы
	tickets: IGameSet[]; // тиражные лотереи
}

const initialState: CatalogState = {
	gamesets: [],
	tickets: [],
};

interface IGameSetAmountArg {
	gameID: IGame['game_id'];
	amount: number;
}

export const catalogSlice = createSlice({
	name: 'catalog',
	initialState,
	reducers: {
		setGameSets: (state, action: PayloadAction<IGameSet[]>) => {
			state.gamesets = action.payload;
		},
		setGameTickets: (state, action: PayloadAction<IGameSet[]>) => {
			state.tickets = action.payload;
		},
		// eslint-disable-next-line consistent-return
		setGameSetAmount(state, { payload }: PayloadAction<IGameSetAmountArg>) {
			const { gameID, amount } = payload;
			const newGameSets = state.gamesets.slice();
			const targetItem = newGameSets.find((it) => it.game.game_id === gameID);

			if (!targetItem) {
				return state;
			}

			targetItem.amount = amount;
			state.gamesets = newGameSets;
		},
		// eslint-disable-next-line consistent-return
		setTicketsAmount(state, { payload }: PayloadAction<IGameSetAmountArg>) {
			const { gameID, amount } = payload;
			const newTickets = state.tickets.slice();
			const targetItem = newTickets.find((it) => it.game.game_id === gameID);

			if (!targetItem) {
				return state;
			}

			targetItem.amount = amount;
			state.tickets = newTickets;
		},
	},
});

export const { setGameSets, setGameSetAmount, setGameTickets, setTicketsAmount } = catalogSlice.actions;

export const selectGameSets = (state: RootState) => state.catalog.gamesets;
export const selectTickets = (state: RootState) => state.catalog.tickets;

export default catalogSlice.reducer;
