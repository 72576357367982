export const dateFormatter = new Intl.DateTimeFormat('ru');

export const timeFormatter = new Intl.DateTimeFormat('ru', {
	hour: 'numeric',
	minute: 'numeric',
});

export const priceFormatter = Intl.NumberFormat('ru', {
	style: 'decimal',
	minimumFractionDigits: 0,
});
