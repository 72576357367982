import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from './GameCard.module.scss';
import { Counter } from '../components/Counter';
import { BuyButton } from '../components/BuyButton';
import { DrawInfo } from '../../../components/DrawInfo';

import { IGame } from '../../../interfaces';
import { priceFormatter } from '../utils';
import { noop } from '../../../utils/noop';
import { moneyFromMDE } from '../../../utils/dataUtils';

export interface IGameCardProps {
	className?: string;
	game: IGame;
	ticketsAmount?: number;
	onSetNumberChange?: (value: number, gameId: IGame['game_id']) => void;
	onBuy?: (value: number, gameId: IGame['game_id']) => void;
}

export function GameCard({
	className,
	game,
	ticketsAmount = 0,
	onSetNumberChange = noop,
	onBuy = noop,
}: IGameCardProps) {
	const { t } = useTranslation('gameCard');
	const { game_id, name, logo, price, draw_number, draw_date, jackpot, prize_name } = game;
	const drawDate = typeof draw_date === 'string' ? new Date(draw_date) : draw_date;

	const handleBuyClick = () => {
		onBuy(ticketsAmount, game_id);
	};

	const handleSetsNumberChange = (value: number) => {
		onSetNumberChange(value, game_id);
	};

	return (
		<article className={cn(className, styles.root, styles[game_id])}>
			<div className={styles.block1}>
				<img src={logo} alt={name} loading="lazy" className={styles.logo} />
				<p className={styles.setsNumber}>{t('ticketsNumber')}</p>
				<Counter
					className={styles.counter}
					value={ticketsAmount}
					min={1}
					max={100}
					variant={game_id === 'bet_cl_649' ? 'gold2' : 'gold1'}
					onChange={handleSetsNumberChange}
				/>
			</div>

			<div className={styles.block2}>
				<h2 className={styles.title}>{prize_name}</h2>

				<p className={styles.price}>
					{priceFormatter.format(moneyFromMDE(jackpot))} <span className={styles.currencySign}>₸</span>
				</p>

				<DrawInfo className={styles.circulation} date={drawDate} drawNumber={draw_number} />

				<BuyButton
					value={moneyFromMDE(price * ticketsAmount)}
					className={cn(styles.buyButton, game_id === 'bet_cl_649' && styles.buyButton_Shadow)}
					onClick={handleBuyClick}
				/>
			</div>
		</article>
	);
}
