import { getRandomUniqueNumbersInRange } from './utils';

// Формируем матрицу 9 на 3 (9 столбцов, 3 строки)
// в каждый из столбцов попадают числа:
// 	1-9
// 10-19
// 20-29
// 30-39
// 40-49
// 50-59
// 60-69
// 70-79
// 80-90
// не больше трех чисел в столбце
// не больше 5 чисел в строке
// Сортируем по возрастанию слева направо по строке и сверху вниз по столбцу

export const generateTelebingoBet = () => {
	const rowsArray: number[][] = [[], [], []];
	const colsArray: number[][] = [];
	// Заполняем столбцы
	for (let i = 0; i < 9; i++) {
		if (i === 0) {
			colsArray.push(getRandomUniqueNumbersInRange(i + 1, i + 9, 3));
		} else if (i === 8) {
			colsArray.push(getRandomUniqueNumbersInRange(i * 10, (i + 1) * 10, 3));
		} else {
			colsArray.push(getRandomUniqueNumbersInRange(i * 10, (i + 1) * 10 - 1, 3));
		}
	}
	// Формируем строки
	for (let i = 0; i < rowsArray.length; i++) {
		for (let j = 0; j < colsArray.length; j++) {
			rowsArray[i].push(colsArray[j][i]);
		}
	}
	// Формируем матрицу с индексами для создания пропусков чисел(пустых ячеек) в каждой строке
	const randomNullIndexArray = [];
	for (let i = 0; i < rowsArray.length; i++) {
		randomNullIndexArray.push(getRandomUniqueNumbersInRange(0, 5, 4));
	}
	// "Накладываем" матрицу с индексами на матрицу билета для создания пустых ячеек
	for (let i = 0; i < randomNullIndexArray.length; i++) {
		for (let j = 0; j < randomNullIndexArray[i].length; j++) {
			rowsArray[i].splice(randomNullIndexArray[i][j], 1);
		}
	}
	// Формируем одномерный массив для отправки на сервер
	let resultArrayBets: number[] = [];
	for (let i = 0; i < rowsArray.length; i++) {
		resultArrayBets = [...resultArrayBets, ...rowsArray[i]];
	}
	return [0, 15, ...resultArrayBets];
};
