import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { getSMSVerificationCode } from '../api/getSMSVerificationCode';
import { setCode, setSubmitPhoneStatus } from './registrationSlice';
import { setLoading } from './appSlice';
import { telegram } from '../utils/telegram';

export const getSMSVerificationCodeThunk = createAsyncThunk(
	'registration/getSMSCode',
	async (phone: string, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			dispatch(setSubmitPhoneStatus('pending'));
			const { data } = await getSMSVerificationCode(phone);
			const { code, error } = data;

			if (error) {
				dispatch(setLoading(false));

				telegram.showPopup({
					title: i18next.t('common.error'),
					message: i18next.t('common.errorCode', { code: error.code }),
				});

				console.error(error);
			}

			if (code) {
				dispatch(setCode(code));
			}
		} catch (error) {
			dispatch(setLoading(false));
			telegram.showAlert(i18next.t('common.networkError'));
			console.error(error);
		} finally {
			dispatch(setSubmitPhoneStatus('idle'));
			dispatch(setLoading(false));
		}
	},
);
