import { ComponentPropsWithoutRef } from 'react';
import cn from 'classnames';

import styles from './CounterButton.module.scss';

interface IProps extends ComponentPropsWithoutRef<'button'> {
	variant: 'minus' | 'plus';
	color?: 'gold' | 'blue';
}

export function CounterButton({ className, variant, color = 'gold', ...props }: IProps) {
	const iconStyle = variant === 'minus' ? styles.minus : styles.plus;
	// eslint-disable-next-line jsx-a11y/control-has-associated-label
	return (
		<button
			{...props}
			type="button"
			className={cn(className, styles.root, iconStyle, color === 'blue' && styles.colorBlue)}
		/>
	);
}
