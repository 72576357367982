import { AxiosResponse } from 'axios';
import { microserviceInstance } from './instance';
import { ILotteryAPIOrderStatus } from '../interfaces';
import { orderStatusMock } from '../__mocks__/orderStatusMock';

const validateDate = (date: string | number) => {
	if (typeof date === 'number') {
		return !Number.isNaN(new Date(date).getTime());
	}

	return !Number.isNaN(new Date(`${date}T00:00:00+06:00`).getTime());
};

const isFakeAPI = process.env.REACT_APP_FAKE_API === 'true';

/**
 * Лотерейный модуль API
 * 4.8. Запросить текущий статус заказа /gporder/status
 */
export const getLotteryAPIOrderStatus = async (orderID: string): Promise<AxiosResponse<ILotteryAPIOrderStatus>> => {
	let result: AxiosResponse<ILotteryAPIOrderStatus>;

	if (isFakeAPI) {
		result = {
			data: orderStatusMock,
		} as AxiosResponse<ILotteryAPIOrderStatus>;
	} else {
		result = await microserviceInstance.post<ILotteryAPIOrderStatus>('/gporder/status', { m_order_id: orderID });
	}

	const payload = result.data;
	const tickets = payload.ticket_data;

	if (!tickets) {
		return result;
	}

	// если draw_date приходит в виде строки в формате ГГГГ-ММ-ДД (это в часовом поясе АЛМ), исправляем ее на формат ISO 8601
	const correctTickets = tickets.map((ticket) => {
		const correctBets = ticket.bet_data.map((bet) => {
			const drawDate = bet.draw_date;

			if (!drawDate || !validateDate(drawDate)) {
				const newBet = { ...bet };
				delete newBet.draw_date;

				return newBet;
			}

			if (typeof drawDate === 'number') {
				return bet;
			}

			return {
				...bet,
				draw_date: `${drawDate}T00:00:00+06:00`,
			};
		});

		return {
			...ticket,
			bet_data: correctBets,
		};
	});

	return {
		...result,
		data: {
			...payload,
			ticket_data: correctTickets,
		},
	};
};
